import React from 'react';
import InputMask from 'react-input-mask';
import ReCAPTCHA from 'react-google-recaptcha';
import loader from '../assets/img/loader.gif';
require('es6-promise').polyfill();
require('isomorphic-fetch');

const API_URL = 'https://greenfield.emlsdr.ru/backend/api';
const recaptchaRef = React.createRef();
const recaptchaRef2 = React.createRef();
const recaptchaRef3 = React.createRef();
const recaptchaRef4 = React.createRef();

export default class Modals extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      your_site_key: '6LcZvb0UAAAAAGWachHAtQkfEN1WtATm-nSiVDgJ',
      submit: false,
      checkImg: '',
      loading: false,
      citySugg: [],
      modal__reg: false,
      modal__lk: false,
      modal__login: false,
      modal__login__pass: false,
      modal__recovery: false,
      modal__change: false,
      modal__addData: false,
      modal__change_data: false,
      modal__feedback: false,
      modal__info_1: false,
      modal__info_2: false,
      modal__info_3: false,
      modal__info_4: false,
      modal__info_5: false,
      modal__info_6: false,
      modal__info_7: false,
      modal__info_8: false,
      modal__info_9: false,
      modal__info_10: false,
      modal__new_action: false,
      modal__prize: false,
      modal__shops: false,
      modal__del: false,
      modal10Text: '',
      modal10Title: '',
      passType: 'password',
      data: {
        login: {
          email: '',
          emailFocus: false,
          password: '',
          passwordFocus: false,
          agree1: false,
          captch: '',
          captchFocus: false,
        },
        addData: {
          name: '',
          nameFocus: false,
          email: '',
          emailFocus: false,
          phone: '',
          phoneFocus: false,
          city: '',
          cityFocus: false,
          street: '',
          streetFocus: false,
          home: '',
          homeFocus: false,
          flat: '',
          flatFocus: false,
          captch: '',
          captchFocus: false,
        },
        reg: {
          name: '',
          nameFocus: false,
          surname: '',
          surnameFocus: false,
          email: '',
          emailFocus: false,
          phone: '',
          phoneFocus: false,
          numberCart: '',
          numberCartFocus: false,
          promocode2: '',
          promocode2Focus: false,
          agree1: false,
          agree2: false,
          agree3: false,
          city: '',
          cityFocus: false,
          street: '',
          streetFocus: false,
          home: '',
          homeFocus: false,
          flat: '',
          flatFocus: false,
          postalIndex: '',
          postalIndexFocus: false,
          captch: '',
          captchFocus: false,
        },
        changeData: {
          name: '',
          nameFocus: false,
          email: '',
          emailFocus: false,
          phone: '',
          phoneFocus: false,
          numberCart: '',
          numberCartFocus: false,
          agree1: false,
          captch: '',
          captchFocus: false,
        },
        recovery: {
          email: '',
          emailFocus: false,
          captch: '',
          captchFocus: false,
        },
        confirm: {
          email: '',
          emailFocus: false,
        },
        change: {
          pass1: '',
          pass1Focus: false,
          pass2: '',
          pass2Focus: false,
          pass3: '',
          pass3Focus: false,
          captch: '',
          captchFocus: false,
        },
        feedback: {
          email: '',
          emailFocus: false,
          name: '',
          nameFocus: false,
          topic: null,
          topicFocus: false,
          message: '',
          messageFocus: false,
          agree: false,
          captch: '',
          captchFocus: false,
        },
        prizes: {
          prize_name: 'шоппер',
          prize_price: '150',
          prize_id: 0,
        },
      },
      errors: {
        login: {
          email: false,
          emailText: 'Введен некорректный e-mail',
          password: false,
          passwordText: 'Введен некорректный пароль',
          captch: false,
          captchText: 'Подтвердите, что Вы не робот!',
          agree1: false,
          agree1Text: 'Необходимо ваше согласие',
        },
        adddata: {
          name: false,
          nameText: 'Введено некорректное имя',
          // name: false,
          // nameText: 'Введено некорректно имя',
          email: false,
          emailText: 'Введен некорректный e-mail',
          phone: false,
          phoneText: 'Введен некорректный телефон',
        },
        reg: {
          name: false,
          nameText: 'Введено некорректное имя',
          name: false,
          nameText: 'Введена некорректная фамилия',
          promocode2: false,
          promocode2Text: 'Введен некорректный промокод',
          email: false,
          emailText: 'Введен некорректный e-mail',
          phone: false,
          phoneText: 'Введен некорректный телефон',
          city: false,
          cityText: 'Введен некорректный город',
          street: false,
          streetText: 'Введена некорректная улица',
          home: false,
          homeText: 'Введен некорректный дом',
          flat: false,
          flatText: 'Введена некорректная квартира',
          postalIndex: false,
          postalIndexText: 'Введен некорректный индекс',
          numberCart: false,
          numberCartText: 'Введен некорректный номер карты',
          agree1: false,
          agree1Text: 'Необходимо ваше согласие',
          agree2: false,
          agree2Text: 'Необходимо ваше согласие',
          agree3: false,
          agree3Text: 'Необходимо ваше согласие',
          captch: false,
          captchText: 'Подтвердите, что Вы не робот!',
        },
        recovery: {
          email: false,
          emailText: 'Введен некорректный e-mail',
        },
        confirm: {
          email: false,
          emailText: 'Введен некорректный e-mail',
        },
        change: {
          pass1: false,
          pass1Text: 'Введен некорректный пароль',
          pass2: false,
          pass2Text: 'Введен некорректный пароль',
          pass3: false,
          pass3Text: 'Введен некорректный пароль',
          captch: false,
          captchText: 'Подтвердите, что Вы не робот!',
          errorStatus: false,
          errorMessage: '',
        },
        feedback: {
          email: false,
          name: false,
          topic: false,
          message: false,
          nameText: 'Введено некорректное имя',
          emailText: 'Введен некорректный e-mail',
          topicText: 'Ввыберите тему сообщения',
          messageText: 'Сообщение должно быть больше 5 символов',
          agree: false,
          agreeText: 'Необходимо ваше согласие',
          captch: false,
          captchText: 'Подтвердите, что Вы не робот!',
        },
      },
      prize_name: 'шоппер',
      prize_price: '150',
      prize_id: 1,
      topicInput: '',
      topicDropdown: false,
      modalTitleInfo: '',
      f_file: null,
      f_file_label: 'Изображение',
      agreement: 'agreement1.pdf',
      confidential: 'confidential1.pdf',
      pravila: 'rules.pdf',
    };
  }

  componentDidMount() {
    if (
      localStorage.getItem('auth_key') &&
      localStorage.getItem('auth_key') !== null &&
      localStorage.getItem('auth_key') !== undefined
    ) {
      this.getProfile();
    }
    if (!localStorage.getItem('info829')) {
      this.info8Show();
      localStorage.setItem('info829', 1);
    }
    // this.info8Show();
    // this.infoNewAction();
  }
  componentWillMount() {}

  componentWillUnmount() {}
  getProfile = async () => {
    await fetch(API_URL + '/getProfile', {
      method: 'GET',
      headers: {
        'x-auth-token': localStorage.getItem('auth_key'),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response !== 'undefined' && response.result === true) {
          if (response.data.profile.userUuid !== '') {
            setTimeout(() => {
              this.props.data.setState({
                banner: response.data.banner,
              });
              if (response.data.profile.name) {
                localStorage.setItem('auth_name', response.data.profile.name);
                this.props.data.setState({
                  name: response.data.profile.name,
                });
              }
              if (response.data.profile.email) {
                localStorage.setItem('email', response.data.profile.email);
                this.props.data.setState({
                  email: response.data.profile.email,
                });
              }
              if (response.data.profile.phone) {
                localStorage.setItem('tel', response.data.profile.phone);
                this.props.data.setState({
                  phone: response.data.profile.phone,
                });
              }
              if (response.data.profile.balance) {
                localStorage.setItem('balance', response.data.profile.balance);
                this.props.data.setState({
                  balance: response.data.profile.balance,
                });
              }
              if (response.data.delivery.city) {
                localStorage.setItem('city', response.data.delivery.city);
                this.props.data.setState({
                  city: response.data.delivery.city,
                });
              }
              if (response.data.delivery.street) {
                localStorage.setItem('street', response.data.delivery.street);
                this.props.data.setState({
                  street: response.data.delivery.street,
                });
              }
              if (response.data.delivery.house) {
                localStorage.setItem('home', response.data.delivery.house);
                this.props.data.setState({
                  home: response.data.delivery.house,
                });
              }
              if (response.data.delivery.flat) {
                localStorage.setItem('flat', response.data.delivery.flat);
                this.props.data.setState({
                  flat: response.data.delivery.flat,
                });
              }
            }, 100);
          }
        } else {
        }
      });
  };
  getCity = (query) => {
    let xhttp = new XMLHttpRequest();
    let requestPayload = {
      query: query,
      count: 20,
      from_bound: { value: 'city' },
      to_bound: { value: 'city' },
      restrict_value: true,
    };

    xhttp.open('POST', 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address?5');
    xhttp.setRequestHeader('Accept', 'application/json');
    xhttp.setRequestHeader('Authorization', 'Token d1487020dd0742e0ccdd3ff24fa5d7ccb45ca3bd');
    xhttp.setRequestHeader('Content-Type', 'application/json');
    xhttp.send(JSON.stringify(requestPayload));
    xhttp.onreadystatechange = () => {
      if (!xhttp || xhttp.readyState !== 4) {
        return;
      }
      if (xhttp.status === 200) {
        let responseJson = JSON.parse(xhttp.response);
        if (responseJson && responseJson.suggestions) {
          this.setState({
            citySugg: responseJson.suggestions,
          });
        }
      }
    };
  };

  btnAnalitic = (pixId) => {
    if (typeof __GetI === 'undefined') {
      window.__GetI = [];
    }
    (function () {
      var p = {
        type: 'CONVERSION',
        /* config START */
        site_id: '8835',
        order: [],
        transaction_id: '',
        revenue: '',
        pixel_id: pixId,
        /* config END */
      };
      window.__GetI.push(p);
      var domain =
        typeof window.__GetI_domain == 'undefined' ? 'px.adhigh.net' : window.__GetI_domain;
      var src =
        ('https:' == document.location.protocol ? 'https://' : 'http://') + domain + '/t.js';
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(script, s);
    })();
  };

  modalClose = () => {
    document.body.classList.remove('overflow-disabled');
    this.setState({
      loading: false,
      modal__reg: false,
      modal__lk: false,
      modal__login: false,
      modal__login__pass: false,
      modal__change_data: false,
      modal__change: false,
      modal__addData: false,
      modal__feedback: false,
      modal__info_1: false,
      modal__info_2: false,
      modal__info_3: false,
      modal__info_4: false,
      modal__info_5: false,
      modal__info_6: false,
      modal__info_7: false,
      modal__info_8: false,
      modal__info_9: false,
      modal__info_10: false,
      modal__prize: false,
      modal__shops: false,
      modal__del: false,
      modal__recovery: false,
      modal__new_action: false,
      modal10Text: '',
      modal10Title: '',
      checkImg: '',
      data: {
        login: {
          email: '',
          password: '',
          remember: true,
        },
        reg: {
          name: '',
          nameFocus: false,
          surname: '',
          surnameFocus: false,
          email: '',
          emailFocus: false,
          promocode2: '',
          promocode2Focus: false,
          phone: '',
          phoneFocus: false,
          city: '',
          cityFocus: false,
          street: '',
          streetFocus: false,
          home: '',
          homeFocus: false,
          flat: '',
          flatFocus: false,
          postalIndex: '',
          postalIndexFocus: false,
          numberCart: '',
          numberCartFocus: false,
          agree1: false,
          agree2: false,
          agree3: false,
          captch: '',
          captchFocus: false,
        },
        recovery: {
          email: '',
          captch: '',
          captchFocus: false,
        },
        confirm: {
          email: '',
        },
        change: {
          pass1: '',
          pass1Focus: false,
          pass2: '',
          pass2Focus: false,
          pass3: '',
          pass3Focus: false,
          captch: '',
          captchFocus: false,
        },
        feedback: {
          email: '',
          emailFocus: false,
          name: '',
          nameFocus: false,
          topic: null,
          topicFocus: false,
          message: '',
          messageFocus: false,
          agree: true,
          captch: '',
          captchFocus: false,
        },
      },
      errors: {
        login: {
          email: false,
          emailText: 'Введен некорректный e-mail',
          password: false,
          passwordText: 'Введен некорректный пароль',
        },
        reg: {
          name: false,
          nameText: 'Введено некорректное имя',
          surname: false,
          surnameText: 'Введена некорректная фамилия',
          email: false,
          emailText: 'Введен некорректный e-mail',
          promocode2: false,
          promocode2Text: 'Введен некорректный промокод',
          phone: false,
          phoneText: 'Введен некорректный телефон',
          city: false,
          cityText: 'Введен некорректный город',
          street: false,
          streetText: 'Введена некорректная улица',
          home: false,
          homeText: 'Введен некорректный дом',
          flat: false,
          flatText: 'Введена некорректная квартира',
          postalIndex: false,
          postalIndexText: 'Введен некорректный индекс',
          numberCart: false,
          numberCartText: 'Введен некорректный номер карты',
          agree1: false,
          agree1Text: 'Необходимо ваше согласие',
          agree2: false,
          agree2Text: 'Необходимо ваше согласие',
          captch: false,
          captchText: 'Подтвердите, что Вы не робот!',
        },
        recovery: {
          email: false,
          emailText: 'Введен некорректный e-mail',
          captch: false,
          captchText: 'Подтвердите, что Вы не робот!',
        },
        confirm: {
          email: false,
          emailText: 'Введен некорректный e-mail',
        },
        change: {
          pass1: false,
          pass1Text: 'Введен некорректный пароль',
          pass2: false,
          pass2Text: 'Введен некорректный пароль',
          pass3: false,
          pass3Text: 'Введен некорректный пароль',
          captch: false,
          captchText: 'Подтвердите, что Вы не робот!',
        },
        feedback: {
          email: false,
          name: false,
          topic: false,
          message: false,
          nameText: 'Введено некорректное имя',
          emailText: 'Введен некорректный e-mail',
          topicText: 'Ввыберите тему сообщения',
          messageText: 'Сообщение должно быть больше 5 символов',
          agree: false,
          agreeText: 'Необходимо ваше согласие',
          captch: false,
          captchText: 'Подтвердите, что Вы не робот!',
        },
      },
    });
  };

  recoveryShow = () => {
    this.modalClose();
    this.setState(
      {
        modal__recovery: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  regShow = () => {
    this.modalClose();
    this.setState(
      {
        modal__reg: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  lkShow = () => {
    this.modalClose();
    this.setState(
      {
        modal__lk: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  delShow = () => {
    this.modalClose();
    this.setState(
      {
        modal__del: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  loginShow = () => {
    window.dataLayer.push({
      event: 'gaEvent',
      eventCategory: 'internal',
      eventAction: 'do',
      eventLabel: 'auth_open',
    });
    this.modalClose();
    this.setState(
      {
        modal__login: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  addDataShow = () => {
    this.modalClose();
    this.setState(
      {
        modal__addData: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  loginPassShow = () => {
    this.modalClose();
    this.setState(
      {
        modal__login__pass: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  changeDataShow = () => {
    this.getProfile();
    this.modalClose();
    this.setState(
      {
        modal__change_data: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  changeShow = () => {
    this.modalClose();
    this.setState(
      {
        modal__change: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  feedbackShow = (showPrize) => {
    this.modalClose();
    this.setState(
      {
        modal__feedback: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  shopShow = () => {
    this.modalClose();
    this.setState(
      {
        modal__shops: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  info1Show = () => {
    this.modalClose();
    this.setState(
      {
        modal__info_1: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  info2Show = () => {
    this.modalClose();
    this.setState(
      {
        modal__info_2: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  info3Show = () => {
    this.modalClose();
    this.setState(
      {
        modal__info_3: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  info4Show = () => {
    this.modalClose();
    this.setState(
      {
        modal__info_4: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  prizeShow = (name, price, id) => {
    this.modalClose();
    this.setState(
      {
        prize_name: name,
        prize_price: price,
        prize_id: id,
        modal__prize: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  info5Show = (title) => {
    this.modalClose();
    this.setState(
      {
        modal__info_5: true,
        modalTitleInfo: title,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };
  info6Show = (title) => {
    this.modalClose();
    this.setState(
      {
        modal__info_6: true,
        modalTitleInfo: title,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  info7Show = (title) => {
    this.modalClose();
    this.setState(
      {
        modal__info_7: true,
        modalTitleInfo: title,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  info10Show = (title, text) => {
    this.modalClose();
    this.setState(
      {
        modal__info_10: true,
        modal10Text: text,
        modal10Title: title,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };
  info8Show = (title) => {
    this.modalClose();
    this.setState(
      {
        modal__info_8: true,
        modalTitleInfo: title,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };
  infoNewAction = (title) => {
    this.modalClose();
    this.setState(
      {
        modal__new_action: true,
        modalTitleInfo: title,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  render5Show = () => {
    return (
      <div
        data-show={this.state.modal__info_5}
        className="modal__custom modal__custom5 modal__custom-info modal__custom-small">
        <div className="modal__box">
          <div
            className="modal__close d-flex align-items-center justify-content-center"
            onClick={this.modalClose}></div>
          <div className="modal__box-content">
            <div className="modal__box-title">{this.state.modalTitleInfo}</div>
          </div>
        </div>
      </div>
    );
  };

  render6Show = () => {
    return (
      <div
        data-show={this.state.modal__info_6}
        className="modal__custom modal__custom5 modal__custom-info modal__custom-small">
        <div className="modal__box">
          <div
            className="modal__close d-flex align-items-center justify-content-center"
            onClick={this.modalClose}></div>
          <div className="modal__box-content">
            <div className="modal__box-title text-center">
              Уважаемый Участник!
              <br />
              В&nbsp;связи с&nbsp;нарушениями Правил Акции мы&nbsp;вынуждены заблокировать Ваш
              аккаунт и&nbsp;аннулировать право на&nbsp;получение Подарков согласно п.10.1. правил
              Акции.
            </div>
          </div>
        </div>
      </div>
    );
  };

  render7Show = () => {
    return (
      <div
        data-show={this.state.modal__info_7}
        className="modal__custom modal__custom5 modal__custom-info modal__custom-small">
        <div className="modal__box">
          <div
            className="modal__close d-flex align-items-center justify-content-center"
            onClick={this.modalClose}></div>
          <div className="modal__box-content text-center d-flex d-f-column">
            <h3 className="apm-title">Ошибка</h3>
            <div className="modal__box-text">{this.state.modalTitleInfo}</div>
            <div className="box-chat hover2">
              <a
                href="https://t.me/greenfieldworld_bot"
                target="_BLANK"
                onMouseOver={() => {
                  this.hoverChatAdd(1);
                }}
                onMouseOut={() => {
                  this.hoverChatRemove(1);
                }}
                onClick={() => {
                  window.dataLayer.push({
                    event: 'gaEvent',
                    eventCategory: 'internal',
                    eventAction: 'click',
                    eventLabel: 'bot_tg',
                  });
                }}>
                <span className="box-chat__text">ПЕРЕЙТИ В ЧАТ-БОТ</span>
                <span className="ico ico-telegramm"></span>
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=79250996576&text=%D0%9D%D0%B0%D1%87%D0%B0%D1%82%D1%8C"
                target="_BLANK"
                onMouseOver={() => {
                  this.hoverChatAdd(2);
                }}
                onMouseOut={() => {
                  this.hoverChatRemove(2);
                }}
                onClick={() => {
                  window.dataLayer.push({
                    event: 'gaEvent',
                    eventCategory: 'internal',
                    eventAction: 'click',
                    eventLabel: 'bot_wa',
                  });
                }}>
                <span className="box-chat__text">ПЕРЕЙТИ В ЧАТ-БОТ</span>
                <span className="ico ico-whatsap"></span>
              </a>
              <a
                href="https://vk.me/greenfieldworld"
                target="_BLANK"
                onMouseOver={() => {
                  this.hoverChatAdd(3);
                }}
                onMouseOut={() => {
                  this.hoverChatRemove(3);
                }}
                onClick={() => {
                  window.dataLayer.push({
                    event: 'gaEvent',
                    eventCategory: 'internal',
                    eventAction: 'click',
                    eventLabel: 'bot_vk',
                  });
                }}>
                <span className="box-chat__text">ПЕРЕЙТИ В ЧАТ-БОТ</span>
                <span className="ico ico-vk"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render8Show = () => {
    return (
      <div
        data-show={this.state.modal__info_8}
        className="modal__custom modal__custom8 modal__custom-info modal__custom-small">
        <div className="modal__box">
          <div
            className="modal__close d-flex align-items-center justify-content-center"
            onClick={this.modalClose}></div>
          <div className="modal__box-content">
            <div className="modal__box-title ">
              Акция завершена. Спасибо за участие!
              <br />
            </div>
            <p>Вручение призов осуществляется до&nbsp;31&nbsp;декабря 2024&nbsp;г.</p>
          </div>
          <div className="step-img step-img-4">
            <div className="step-img-box">
              <div className="step-img-box-img step-img-box-1"></div>
              <div className="step-img-box-img step-img-box-2"></div>
              <div className="step-img-box-img step-img-box-3"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  renderNewAction = () => {
    return (
      <div
        data-show={this.state.modal__new_action}
        className="modal__custom modal__custom8 modal__custom-info modal__custom-small bgNewAction">
        <div className="modal__box">
          <div
            className="modal__close d-flex align-items-center justify-content-center"
            onClick={this.modalClose}></div>
          <div className="modal__box-content">
            <div className="modal__box-title ">
              ОТЛИЧНО!
              <br />
              <br />
            </div>
            <p>
              СЛЕДИТЕ ЗА НОВОСТЯМИ О СТАРТЕ <br />
              НА GREENFIELD-CLUB.RU НАКОПИВ В ЭТОМ МЕСЯЦЕ 
              <br />
              <br />
              500 БАЛЛОВ, ВЫ ПОЛУЧИТЕ В 2025г. 
              <br />
              САМЫЙ ПРИВИЛЕГИРОВАННЫЙ <br />
              PLATINUM СТАТУС, С КОТОРЫМ <br />
              ВАМ БУДУТ ДОСТУПНЫ АБСОЛЮТНО <br />
              ВСЕ ПРИЗЫ И ПРИВИЛЕГИИ 
              <br />
              <br />
              <br />
            </p>
            <button
              // id="apm-scan-qr"
              // className="upload-check btn btn-2 btn-arrow-1"
              className="btn btn-2 btn-arrow-1"
              onClick={() => {
                this.modalClose();
                this.info8Show();
                // if (typeof window.__GetI === 'undefined') {
                //   window.__GetI = [];
                // }
                // (function () {
                //   var p = {
                //     type: 'VIEW',
                //     /* config START */
                //     site_id: '8835',
                //     product_id: '',
                //     product_price: '',
                //     category_id: '',
                //     pixel_id: 'Reg_Chek',
                //     /* config END */
                //   };
                //   window.__GetI.push(p);
                //   var domain =
                //     typeof window.__GetI_domain == 'undefined'
                //       ? 'px.adhigh.net'
                //       : window.__GetI_domain;
                //   var src =
                //     ('https:' == document.location.protocol ? 'https://' : 'http://') +
                //     domain +
                //     '/p.js';
                //   var script = document.createElement('script');
                //   script.type = 'text/javascript';
                //   script.src = src;
                //   var s = document.getElementsByTagName('script')[0];
                //   s.parentNode.insertBefore(script, s);
                // })();

                // window.dataLayer.push({
                //   event: 'gaEvent',
                //   eventCategory: 'internal',
                //   eventAction: 'click',
                //   eventLabel: 'load_cheque_cabinet',
                // });
              }}>
              зарегистрировать чек
            </button>
          </div>
        </div>
      </div>
    );
  };

  checkShow = (img) => {
    this.modalClose();
    this.setState(
      {
        checkImg: img,
        modal__info_9: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };
  render10Show = () => {
    return (
      <div
        data-show={this.state.modal__info_10}
        className="modal__custom modal__custom8 modal__custom-info modal__custom-small">
        <div className="modal__box">
          <div
            className="modal__close d-flex align-items-center justify-content-center"
            onClick={this.modalClose}></div>
          <div className="modal__box-content">
            <div className="modal__box-title ">
              {this.state.modal10Title}
              <br />
            </div>
            <p>
              {this.state.modal10Text.includes('https') ? (
                <a href={this.state.modal10Text} className="link link-sert" target="_BLANK">
                  {this.state.modal10Text}
                </a>
              ) : (
                this.state.modal10Text
              )}
            </p>
          </div>
        </div>
      </div>
    );
  };
  render9Show = () => {
    return (
      <div
        data-show={this.state.modal__info_9}
        className="modal__custom modal__custom8 modal__custom-info modal__custom-small">
        <div className="modal__box">
          <div
            className="modal__close d-flex align-items-center justify-content-center"
            onClick={this.modalClose}></div>
          <div className="modal__box-content">
            <img src={this.state.checkImg} className="img-check"></img>
          </div>
        </div>
      </div>
    );
  };

  renderCitySugg = () => {
    if (this.state.citySugg.length > 0) {
      return this.state.citySugg.map((sugg, index) => (
        <div
          onClick={() => {
            let data = this.state.data;
            data.reg.city = sugg.data.city_with_type;
            this.setState({
              data: data,
              citySugg: [],
            });
          }}
          key={'sugg_' + index.toString()}
          className="sugg__item">
          {sugg.data.city_with_type}
        </div>
      ));
    }
  };

  renderError = (form, field) => {
    switch (form) {
      case 'login':
        switch (field) {
          case 'email':
            if (this.state.errors.login.email === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.login.email = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.login.emailText}
                </div>
              );
            }
            break;
          case 'password':
            if (this.state.errors.login.password === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.login.password = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.login.passwordText}
                </div>
              );
            }
            break;
          case 'captch':
            if (this.state.errors.login.captch === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.login.captch = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.login.captchText}
                </div>
              );
            }
            break;
        }
        break;
      case 'reg':
        switch (field) {
          case 'name':
            if (this.state.errors.reg.name === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.name = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.nameText}
                </div>
              );
            }
            break;
          case 'email':
            if (this.state.errors.reg.email === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.email = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.emailText}
                </div>
              );
            }
            break;
          case 'phone':
            if (this.state.errors.reg.phone === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.phone = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.phoneText}
                </div>
              );
            }
            break;
          case 'city':
            if (this.state.errors.reg.city === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.city = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.cityText}
                </div>
              );
            }
            break;
          case 'street':
            if (this.state.errors.reg.street === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.street = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.streetText}
                </div>
              );
            }
            break;
          case 'home':
            if (this.state.errors.reg.home === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.home = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.homeText}
                </div>
              );
            }
            break;
          case 'flat':
            if (this.state.errors.reg.flat === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.flat = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.flatText}
                </div>
              );
            }
            break;
          case 'postalIndex':
            if (this.state.errors.reg.postalIndex === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.postalIndex = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.postalIndexText}
                </div>
              );
            }
            break;
          case 'agree1':
            if (this.state.errors.reg.agree1 === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.agree1 = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.agree1Text}
                </div>
              );
            }
            break;
          case 'agree2':
            if (this.state.errors.reg.agree2 === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.agree2 = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.agree2Text}
                </div>
              );
            }
            break;
          case 'captch':
            if (this.state.errors.reg.captch === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.captch = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.captchText}
                </div>
              );
            }
            break;
        }
        break;
      case 'addData':
        switch (field) {
          case 'name':
            if (this.state.errors.reg.name === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.name = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.nameText}
                </div>
              );
            }
            break;
          case 'email':
            if (this.state.errors.reg.email === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.email = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.emailText}
                </div>
              );
            }
            break;
          case 'phone':
            if (this.state.errors.reg.phone === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.phone = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.phoneText}
                </div>
              );
            }
            break;
          case 'phone':
            if (this.state.errors.reg.phone === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.phone = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.phoneText}
                </div>
              );
            }
            break;
          case 'city':
            if (this.state.errors.reg.city === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.city = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.cityText}
                </div>
              );
            }
            break;
          case 'street':
            if (this.state.errors.reg.street === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.street = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.streetText}
                </div>
              );
            }
            break;
          case 'home':
            if (this.state.errors.reg.home === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.home = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.homeText}
                </div>
              );
            }
            break;
          case 'flat':
            if (this.state.errors.reg.flat === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.flat = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.flatText}
                </div>
              );
            }
            break;
          case 'captch':
            if (this.state.errors.reg.captch === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.captch = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.captchText}
                </div>
              );
            }
            break;
        }
        break;
      case 'recovery':
        switch (field) {
          case 'email':
            if (this.state.errors.recovery.email === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.recovery.email = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.recovery.emailText}
                </div>
              );
            }
            break;
          case 'captch':
            if (this.state.errors.recovery.captch === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.recovery.captch = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.recovery.captchText}
                </div>
              );
            }
            break;
        }
        break;
      case 'confirm':
        switch (field) {
          case 'email':
            if (this.state.errors.confirm.email === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.confirm.email = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.confirm.emailText}
                </div>
              );
            }
            break;
        }
        break;
      case 'change':
        switch (field) {
          case 'pass1':
            if (this.state.errors.change.pass1 === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.change.pass1 = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.change.pass1Text}
                </div>
              );
            }
            break;
          case 'pass2':
            if (this.state.errors.change.pass2 === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.change.pass2 = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.change.pass2Text}
                </div>
              );
            }
            break;
          case 'pass3':
            if (this.state.errors.change.pass3 === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.change.pass3 = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.change.pass3Text}
                </div>
              );
            }
            break;
          case 'error':
            if (this.state.errors.change.errorStatus === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.change.errorStatus = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.change.errorMessage}
                </div>
              );
            }
            break;
        }
        break;
      case 'feedback':
        switch (field) {
          case 'name':
            if (this.state.errors.feedback.name === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.name = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.nameText}
                </div>
              );
            }
            break;
          case 'email':
            if (this.state.errors.feedback.email === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.email = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.emailText}
                </div>
              );
            }
            break;
          case 'topic':
            if (this.state.errors.feedback.topic === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.topic = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.topicText}
                </div>
              );
            }
            break;
          case 'message':
            if (this.state.errors.feedback.message === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.message = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.messageText}
                </div>
              );
            }
            break;
          case 'agree':
            if (this.state.errors.feedback.agree === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.agree = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.agreeText}
                </div>
              );
            }
            break;
          case 'captch':
            if (this.state.errors.feedback.captch === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.captch = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.captchText}
                </div>
              );
            }
            break;
        }
        break;
    }
  };

  renderModalPrize = () => {
    return (
      <div
        data-show={this.state.modal__prize}
        className="modal__custom modal__custom modal__custom-prize modal__custom-small">
        <div className="modal__box">
          <div
            className="modal__close d-flex align-items-center justify-content-center"
            onClick={() => {
              this.modalClose();
              window.dataLayer.push({
                event: 'gaEvent',
                eventCategory: 'internal',
                eventAction: 'do',
                eventLabel: 'order_close',
              });
            }}></div>
          <div className="modal__box-content">
            <div className="modal__box-title">Подтверждение заказа</div>
            <div className="modal__form prize">
              <div className="prize-name">{this.state.prize_name}</div>
              <div className="prize-price">
                {this.state.prize_price}
                <span>баллов</span>
              </div>
              <div
                className="prize-btn btn btn-1 btn-arrow-1"
                onClick={() => {
                  this.buyPrize(this.state.prize_id);
                  window.dataLayer.push({
                    event: 'gaEvent',
                    eventCategory: 'internal',
                    eventAction: 'click',
                    eventLabel: 'order',
                  });
                }}>
                Заказать
              </div>
              <div
                className={
                  'section-prize-' +
                  this.state.prize_id +
                  '-icon prize-img prize-img-' +
                  this.state.prize_id
                }></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  submitLogin = async () => {
    let errors = this.state.errors;
    let submit_enable = true;
    if (this.state.data.login.email === '') {
      errors.login.email = true;
      submit_enable = false;
    } else {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.state.data.login.email)) {
        errors.login.email = true;
        submit_enable = false;
      } else {
        errors.login.email = false;
      }
    }

    if (this.state.data.login.password === '') {
      errors.login.password = true;
      submit_enable = false;
    } else {
      errors.login.password = false;
    }

    this.setState({
      errors: errors,
    });

    if (submit_enable === true) {
      this.setState({
        loading: true,
      });
      const formData = new FormData();
      formData.append('login', this.state.data.login.email);
      formData.append('password', this.state.data.login.password.replace(/\s/g, ''));
      await fetch(API_URL + '/authenticateByEmail', {
        method: 'POST',
        body: formData,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (response.result === true) {
            // window.gtag('event', 'do', {
            //   event_category: 'internal',
            //   event_label: 'successfull_login',
            // });
            // window.ym(84228847, 'reachGoal', 'authenticationSuccess');
            // window.dataLayer.push({
            //   event: 'gaEvent',
            //   eventCategory: 'internal',
            //   eventAction: 'do',
            //   eventLabel: 'successfull_login',
            // });
            // console.log(response);
            localStorage.setItem('auth_key', response.data.auth_key);
            // localStorage.setItem('auth_name', response.name);
            // localStorage.setItem('email', response.email);
            // localStorage.setItem('tel', response.phone);
            // localStorage.setItem('scores', response.sum);
            // localStorage.setItem('cardNumber', response.cardNumber);
            /*window.location.reload();*/
            window.location.replace('/profile');
          } else {
            if (response.error.frod) {
              this.info6Show(
                'Уважаемый Участник!<br /> В связи с нарушениями Правил Акции мы вынуждены заблокировать Ваш аккаунт и аннулировать право на получение Подарков согласно п.10.1. правил Акции.',
              );
            }
            if (response.error.password !== undefined) {
              errors.login.password = true;
              errors.login.passwordText = response.error.password;
            }

            if (response.error.login !== undefined) {
              errors.login.email = true;
              errors.login.emailText = response.error.login[0];
            }

            if (response.error.recaptcha !== undefined) {
              errors.login.captch = true;
              errors.login.captchText = response.message.recaptcha[0];
              recaptchaRef.current.reset();
            }

            this.setState({
              errors: errors,
            });
          }

          this.setState({
            loading: false,
          });
        });
    }
  };

  submitReg = async () => {
    let errors = this.state.errors;
    let submit_enable = true;

    // this.info5Show(
    //   'Чек принят в обработку. Сохраняйте оригинал чека до конца проведения программы лояльности.',
    // );

    if (this.state.data.reg.email === '') {
      errors.reg.email = true;
      submit_enable = false;
    } else {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.state.data.reg.email)) {
        errors.reg.email = true;
        submit_enable = false;
      } else {
        errors.reg.email = false;
      }
    }

    if (this.state.data.reg.name === '') {
      errors.reg.name = true;
      submit_enable = false;
    } else {
      errors.reg.name = false;
    }

    if (this.state.data.reg.phone === '') {
      errors.reg.phone = true;
      submit_enable = false;
    } else {
      errors.reg.phone = false;
    }

    if (!this.state.data.reg.agree1) {
      errors.reg.agree1 = true;
      submit_enable = false;
    } else {
      errors.reg.agree1 = false;
    }
    if (!this.state.data.reg.agree2) {
      errors.reg.agree2 = true;
      submit_enable = false;
    } else {
      errors.reg.agree2 = false;
    }

    this.setState({
      errors: errors,
    });

    if (submit_enable === true) {
      this.setState({
        loading: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });
      const formData = new FormData();
      formData.append('login', this.state.data.reg.email);
      formData.append('name', this.state.data.reg.name);
      formData.append('phone', this.state.data.reg.phone);
      formData.append('promocode2', this.state.data.reg.promocode2);
      formData.append('rules1', 1);
      formData.append('rules2', 1);
      formData.append('rules3', this.state.data.reg.agree3);
      this.btnAnalitic('Otpr_anketa');

      await fetch(API_URL + '/registerByEmail', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.result === true) {
            console.log(response);
            window.generalPixel.postClick('a3');
            // this.props.click('reg');
            window.dataLayer.push({
              event: 'gaEvent',
              eventCategory: 'internal',
              eventAction: 'do',
              eventLabel: 'signup_success',
            });
            this.info5Show('На Вашу почту отправлено письмо с паролем');
            window._tmr.push({ type: 'reachGoal', id: 3259179, goal: 'registration_successful' });
          } else {
            if (response.error.pass !== undefined) {
              errors.reg.pass1 = true;
              errors.reg.pass1Text = response.message.pass[0];
            }

            if (response.error.login !== undefined) {
              errors.reg.email = true;
              errors.reg.emailText = response.error.login[0];
            }

            if (response.error.name !== undefined) {
              errors.reg.name = true;
              errors.reg.nameText = response.error.name[0];
            }
            if (response.error.promocode2 !== undefined) {
              errors.reg.promocode2 = true;
              errors.reg.promocode2Text = response.error.name[0];
            }

            if (response.error.phone !== undefined) {
              errors.reg.phone = true;
              errors.reg.phoneText = response.error.phone[0];
            }
            if (response.error.numberCart !== undefined) {
              errors.reg.numberCart = true;
              errors.reg.numberCartText = response.error.numberCart[0];
            }

            if (response.error.recaptcha !== undefined) {
              errors.reg.captch = true;
              errors.reg.captchText = response.error.recaptcha[0];
              recaptchaRef2.current.reset();
            }

            this.setState({
              errors: errors,
            });
          }

          this.setState({
            loading: false,
          });
        });
    }
  };

  submitAddData = async () => {
    let errors = this.state.errors;
    let submit_enable = true;
    if (this.state.data.reg.email === '') {
      errors.reg.email = true;
      submit_enable = false;
    } else {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.state.data.reg.email)) {
        errors.reg.email = true;
        submit_enable = false;
      } else {
        errors.reg.email = false;
      }
    }

    if (this.state.data.reg.name === '') {
      errors.reg.name = true;
      submit_enable = false;
    } else {
      errors.reg.name = false;
    }

    if (this.state.data.reg.phone === '') {
      errors.reg.phone = true;
      submit_enable = false;
    } else {
      errors.reg.phone = false;
    }

    if (this.state.data.reg.city === '') {
      errors.reg.city = true;
      submit_enable = false;
    } else {
      errors.reg.city = false;
    }

    if (this.state.data.reg.street === '') {
      errors.reg.street = true;
      submit_enable = false;
    } else {
      errors.reg.street = false;
    }

    if (this.state.data.reg.home === '') {
      errors.reg.home = true;
      submit_enable = false;
    } else {
      errors.reg.home = false;
    }

    if (this.state.data.reg.flat === '') {
      errors.reg.flat = true;
      submit_enable = false;
    } else {
      errors.reg.flat = false;
    }

    if (this.state.data.reg.postalIndex === '') {
      errors.reg.postalIndex = true;
      submit_enable = false;
    } else {
      errors.reg.postalIndex = false;
    }

    this.setState({
      errors: errors,
    });

    if (submit_enable === true) {
      this.setState({
        loading: true,
      });
      const formData = new FormData();
      formData.append('email', this.state.data.reg.email);
      formData.append('name', this.state.data.reg.name);
      formData.append('phone', this.state.data.reg.phone);
      formData.append('city', this.state.data.reg.city);
      formData.append('street', this.state.data.reg.street);
      formData.append('house', this.state.data.reg.home);
      formData.append('flat', this.state.data.reg.flat);
      formData.append('postal_index', this.state.data.reg.postalIndex);

      await fetch(API_URL + '/updateAddress', {
        method: 'POST',
        headers: {
          'x-auth-token': localStorage.getItem('auth_key'),
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.result === true) {
            // window.gtag('event', 'do', {
            //   event_category: 'internal',
            //   event_label: 'successfull_registration',
            // });
            // window.ym(84228847, 'reachGoal', 'reistrationSuccess');
            // window.dataLayer.push({
            //   event: 'gaEvent',
            //   eventCategory: 'internal',
            //   eventAction: 'do',
            //   eventLabel: 'successfull_registration',
            // });
            this.props.data.setState({
              city: this.state.data.reg.city,
            });
            this.props.data.setState({
              street: this.state.data.reg.street,
            });
            this.props.data.setState({
              home: this.state.data.reg.home,
            });
            this.props.data.setState({
              flat: this.state.data.reg.flat,
            });
            this.info5Show('Данные сохранены');

            if (localStorage.getItem('idPrize')) {
              setTimeout(() => {
                this.buyPrize(localStorage.getItem('idPrize'));
                localStorage.removeItem('idPrize');
              }, 300);
            }
          } else {
            if (response.error.email !== undefined) {
              errors.reg.email = true;
              errors.reg.emailText = response.error.email[0];
            }

            if (response.error.name !== undefined) {
              errors.reg.name = true;
              errors.reg.nameText = response.error.name[0];
            }

            if (response.error.phone !== undefined) {
              errors.reg.phone = true;
              errors.reg.phoneText = response.error.phone[0];
            }

            if (response.error.city !== undefined) {
              errors.reg.city = true;
              errors.reg.cityText = response.error.city[0];
            }

            if (response.error.street !== undefined) {
              errors.reg.street = true;
              errors.reg.streetText = response.error.street[0];
            }

            if (response.error.home !== undefined) {
              errors.reg.home = true;
              errors.reg.homeText = response.error.home[0];
            }

            if (response.error.flat !== undefined) {
              errors.reg.flat = true;
              errors.reg.flatText = response.error.flat[0];
            }

            this.setState({
              errors: errors,
            });
          }

          this.setState({
            loading: false,
          });
        });
    }
  };

  submitChangeData = async () => {
    let errors = this.state.errors;
    let submit_enable = true;
    if (this.state.data.reg.email === '') {
      errors.reg.email = true;
      submit_enable = false;
    } else {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.state.data.reg.email)) {
        errors.reg.email = true;
        submit_enable = false;
      } else {
        errors.reg.email = false;
      }
    }

    if (this.state.data.reg.name === '') {
      errors.reg.name = true;
      submit_enable = false;
    } else {
      errors.reg.name = false;
    }

    if (this.state.data.reg.phone === '') {
      errors.reg.phone = true;
      submit_enable = false;
    } else {
      errors.reg.phone = false;
    }

    this.setState({
      errors: errors,
    });

    if (submit_enable === true) {
      this.setState({
        loading: true,
      });
      const formData = new FormData();
      formData.append('email', this.state.data.reg.email);
      formData.append('name', this.state.data.reg.name);
      formData.append('phone', this.state.data.reg.phone);
      formData.append('cardNumber', this.state.data.reg.numberCart);

      await fetch(API_URL + '/updateProfile', {
        method: 'POST',
        body: formData,
        headers: {
          'X-Auth-Token': localStorage.getItem('auth_key'),
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error === 0) {
            window.gtag('event', 'do', {
              event_category: 'internal',
              event_label: 'successfull_registration',
            });
            this.info2Show();
          }

          if (response.error === 1) {
            if (response.message.pass !== undefined) {
              errors.reg.pass1 = true;
              errors.reg.pass1Text = response.message.pass[0];
            }

            if (response.message.login !== undefined) {
              errors.reg.email = true;
              errors.reg.emailText = response.message.login[0];
            }

            if (response.message.name !== undefined) {
              errors.reg.name = true;
              errors.reg.nameText = response.message.name[0];
            }

            if (response.message.phone !== undefined) {
              errors.reg.phone = true;
              errors.reg.phoneText = response.message.phone[0];
            }
            if (response.message.numberCart !== undefined) {
              errors.reg.numberCart = true;
              errors.reg.numberCartText = response.message.numberCart[0];
            }

            if (response.message.recaptcha !== undefined) {
              errors.reg.captch = true;
              errors.reg.captchText = response.message.recaptcha[0];
              recaptchaRef2.current.reset();
            }

            this.setState({
              errors: errors,
            });
          }

          this.setState({
            loading: false,
          });
        });
    }
  };

  submitRecovery = async () => {
    let errors = this.state.errors;
    let submit_enable = true;
    if (this.state.data.recovery.email === '') {
      errors.recovery.email = true;
      submit_enable = false;
    } else {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.state.data.recovery.email)) {
        errors.recovery.email = true;
        submit_enable = false;
      } else {
        errors.recovery.email = false;
      }
    }

    this.setState({
      errors: errors,
    });

    if (submit_enable === true) {
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
      localStorage.setItem('login', this.state.data.recovery.email);
      const formData = new URLSearchParams();
      formData.append('email', this.state.data.recovery.email);

      await fetch(API_URL + '/resendPassword', {
        method: 'POST',
        headers: myHeaders,
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.result === true) {
            this.loginPassShow();
          } else {
            if (response.error.email !== undefined) {
              errors.recovery.email = true;
              errors.recovery.emailText = response.error.email[0];
            }
            this.setState({
              errors: errors,
            });
          }

          this.setState({
            loading: false,
          });
        });
    }
  };

  submitChange = async () => {
    // this.state.data.change.captch = recaptchaRef.current.getValue();

    let errors = this.state.errors;
    let submit_enable = true;
    if (this.state.data.change.pass1 === '') {
      errors.change.pass1 = true;
      submit_enable = false;
    } else {
      errors.change.pass1 = false;
    }

    if (this.state.data.change.pass2 === '') {
      errors.change.pass2 = true;
      submit_enable = false;
      errors.pass2Text = 'Введен некорректный пароль';
    } else {
      errors.change.pass2 = false;
    }

    if (this.state.data.change.pass3 === '') {
      errors.change.pass3 = true;
      submit_enable = false;
      errors.pass3Text = 'Введен некорректный пароль';
    } else {
      if (this.state.data.change.pass3 !== this.state.data.change.pass2) {
        errors.change.pass3 = true;
        errors.change.pass3Text = 'Пароли не совпадают';
      } else {
        errors.change.pass3 = false;
      }
    }

    this.setState({
      errors: errors,
    });

    if (submit_enable === true) {
      this.setState({
        loading: true,
      });
      const formData = new FormData();
      formData.append('old_password', this.state.data.change.pass1);
      formData.append('new_password', this.state.data.change.pass2);
      formData.append('new_password_repeat', this.state.data.change.pass3);

      await fetch(API_URL + '/updateProfile', {
        method: 'POST',
        body: formData,
        headers: {
          'X-Auth-Token': localStorage.getItem('auth_key'),
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.result === true) {
            this.info2Show();
          } else if (response.message.old) {
            errors.change.pass1 = true;
            errors.change.pass1Text = response.message.old;
            submit_enable = false;
          } else if (response.message.pass) {
            errors.change.pass2 = true;
            errors.change.pass2Text = response.message.pass;
            submit_enable = false;
          } else {
            errors.errorStatus = true;
            errors.errorMessage = response.message;
          }
          /*if (response.status === "success") {
                    this.setState({
                        modal__successTitle: 'Готово'
                    });
                    this.setState({
                        modal__successText: 'Ваш пароль успешно изменен'
                    });
                    this.successShow()
                }

                if (response.status === "error") {
                    if (response.message.password !== undefined) {
                        errors.change.pass1 = true;
                        errors.change.pass1Text = response.message.password[0];
                    }

                    if (response.message.pass2 !== undefined) {
                        errors.change.pass2 = true;
                        errors.change.pass2Text = response.message.pass2[0];
                    }

                    if (response.message.pass2 !== undefined) {
                        errors.change.pass3 = true;
                        errors.change.pass3Text = response.message.pass2R[0];
                    }

                    this.setState({
                        errors: errors
                    });
                }*/

          this.setState({
            loading: false,
          });
        });
    }
  };

  submitFeedback = async () => {
    let errors = this.state.errors;
    let submit_enable = true;
    if (this.state.data.feedback.email === '') {
      errors.feedback.email = true;
      submit_enable = false;
    } else {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.state.data.feedback.email)) {
        errors.feedback.email = true;
        submit_enable = false;
      } else {
        errors.feedback.email = false;
      }
    }

    if (this.state.data.feedback.name.length < 2) {
      errors.feedback.name = true;
      submit_enable = false;
    } else {
      errors.feedback.name = false;
    }

    if (this.state.data.feedback.topic === null || this.state.data.feedback.topic === '') {
      errors.feedback.topic = true;
      submit_enable = false;
    } else {
      errors.feedback.topic = false;
    }

    if (this.state.data.feedback.message.length < 5) {
      errors.feedback.message = true;
      submit_enable = false;
    } else {
      errors.feedback.message = false;
    }

    if (this.state.data.feedback.agree !== true) {
      errors.feedback.agree = true;
      submit_enable = false;
    } else {
      errors.feedback.agree = false;
    }

    this.setState({
      errors: errors,
    });
    if (submit_enable === true) {
      this.setState({
        loading: true,
      });

      const formData = new FormData();
      const fileInput = document.querySelector('#file');
      formData.append('email', this.state.data.feedback.email);
      formData.append('name', this.state.data.feedback.name);
      formData.append('subject_id', this.state.data.feedback.topic);
      formData.append('text', this.state.data.feedback.message);
      formData.append('accept1 ', 1);
      if (localStorage.getItem('auth_key')) {
        formData.append('auth_key', localStorage.getItem('auth_key'));
      }

      await fetch(API_URL + '/sendFeedback', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.result === true) {
            this.info4Show();
          } else {
            this.setState({
              errors: errors,
            });
          }

          this.setState({
            loading: false,
          });
        });
    }
  };

  toggleTopicDropdown = () => {
    let errors = this.state.errors;
    errors.feedback.topic = false;
    this.setState({
      topicDropdown: !this.state.topicDropdown,
      errors: errors,
    });
  };

  selectTopic = (topic, title) => {
    let data = this.state.data;
    data.feedback.topic = topic;
    let errors = this.state.errors;
    errors.feedback.topic = false;
    this.setState({
      data: data,
      errors: errors,
      topicDropdown: false,
      topicInput: title,
    });
  };

  CaptchaRender = () => {
    return (
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 form__col">
        <div
          className={
            'form__item ' +
            (this.state.data.login.captchFocus === true || this.state.data.login.captch !== ''
              ? 'form-focused'
              : '')
          }>
          <div
            className={[
              'form__field ',
              this.state.errors.login.captch === true ? 'has-error' : '',
            ].join(' ')}>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="compact"
              sitekey="6LcZvb0UAAAAAGWachHAtQkfEN1WtATm-nSiVDgJ"
            />
            {this.renderError('login', 'captch')}
          </div>
        </div>
      </div>
    );
  };
  buyPrize = async (id) => {
    if (localStorage.getItem('auth_key') && localStorage.getItem('auth_key') != 'null') {
      if (this.props.data.state.city != '') {
        const formData = new FormData();
        formData.append('prize_id', id);
        await fetch(API_URL + '/buyPrize', {
          method: 'POST',
          headers: {
            'x-auth-token': localStorage.getItem('auth_key'),
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            if (response && response !== 'undefined' && response.result === true) {
              this.getProfile();
              this.props.getPrizeMy();
              window.dataLayer.push({
                event: 'gaEvent',
                eventCategory: 'internal',
                eventAction: 'do',
                eventLabel: 'order_success',
              });
              this.info5Show('Подарок заказан');
            } else {
              this.info5Show(response.error);
            }
          });
      } else {
        localStorage.setItem('idPrize', id);
        this.addDataShow();
      }
    } else {
      this.loginShow();
    }
  };
  render() {
    return (
      <div className="modals__list">
        <div
          data-show={this.state.modal__shops}
          className="modal__custom-small modal__custom modal__custom-big modal__custom-shops">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={this.modalClose}>
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M25.5 8.5L8.5 25.5"
                  stroke="#009FE3"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.5 8.5L25.5 25.5"
                  stroke="#009FE3"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="modal__box-content">
              <div className="modal__shops-header">
                <div className="modal__shops-title">Где Вы купили продукты Danone?</div>
                <div className="modal__shops-subtitle">
                  Для регистрации чека сначала необходимо выбрать торговую сеть.
                </div>
              </div>
              <div className="shops__list">
                <div className="row justify-content-center">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 shops__item shops__item-dixie">
                    <a
                      onClick={() => {
                        this.modalClose();
                        this.props.runWidget('dixy');
                      }}
                      className="shops__item-block">
                      <div className="shops__item-logo d-flex align-items-center justify-content-center"></div>
                      <div className="shops__item-date">26.10-20.12</div>
                      <div className="arrow">
                        <svg viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M27.4596 5.45962C27.7135 5.20578 27.7135 4.79422 27.4596 4.54038L23.323 0.403805C23.0692 0.149964 22.6576 0.149964 22.4038 0.403805C22.15 0.657646 22.15 1.0692 22.4038 1.32304L26.0808 5L22.4038 8.67695C22.15 8.93079 22.15 9.34235 22.4038 9.59619C22.6576 9.85003 23.0692 9.85003 23.323 9.59619L27.4596 5.45962ZM2.84124e-08 5.65L27 5.65L27 4.35L-2.84124e-08 4.35L2.84124e-08 5.65Z"
                            fill="#A7E5ED"
                          />
                        </svg>
                      </div>
                    </a>
                  </div>
                  {/*<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 shops__item shops__item-billa">
                                        <a onClick={()=>{
                                            this.modalClose();
                                            this.props.runWidget('billa');
                                        }} className="shops__item-block">
                                            <div className="shops__item-logo d-flex align-items-center justify-content-center">
                                            </div>
                                            <div className="shops__item-date">
                                                26.10-29.11
                                            </div>
                                            <div className="arrow">
                                                <svg viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M27.4596 5.45962C27.7135 5.20578 27.7135 4.79422 27.4596 4.54038L23.323 0.403805C23.0692 0.149964 22.6576 0.149964 22.4038 0.403805C22.15 0.657646 22.15 1.0692 22.4038 1.32304L26.0808 5L22.4038 8.67695C22.15 8.93079 22.15 9.34235 22.4038 9.59619C22.6576 9.85003 23.0692 9.85003 23.323 9.59619L27.4596 5.45962ZM2.84124e-08 5.65L27 5.65L27 4.35L-2.84124e-08 4.35L2.84124e-08 5.65Z" fill="#A7E5ED"/>
                                                </svg>
                                            </div>
                                        </a>
                                    </div>*/}
                  {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 shops__item shops__item-lenta">
                                        <a onClick={()=>{
                                            this.modalClose();
                                            this.props.runWidget('lenta');
                                        }} className="shops__item-block">
                                            <div className="shops__item-logo d-flex align-items-center justify-content-center">
                                                <img src={require('../assets/img/lenta.png')} />
                                            </div>
                                            <div className="shops__item-date">
                                                26.10-29.11
                                            </div>
                                            <div className="arrow">
                                                <svg viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M27.4596 5.45962C27.7135 5.20578 27.7135 4.79422 27.4596 4.54038L23.323 0.403805C23.0692 0.149964 22.6576 0.149964 22.4038 0.403805C22.15 0.657646 22.15 1.0692 22.4038 1.32304L26.0808 5L22.4038 8.67695C22.15 8.93079 22.15 9.34235 22.4038 9.59619C22.6576 9.85003 23.0692 9.85003 23.323 9.59619L27.4596 5.45962ZM2.84124e-08 5.65L27 5.65L27 4.35L-2.84124e-08 4.35L2.84124e-08 5.65Z" fill="#A7E5ED"/>
                                                </svg>
                                            </div>
                                        </a>
                                    </div>*/}
                  {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 shops__item shops__item-pyaterochka">
                                        <a onClick={()=>{
                                            this.modalClose();
                                            this.props.runWidget('5ka');
                                        }} className="shops__item-block">
                                            <div className="shops__item-logo d-flex align-items-center justify-content-center">
                                                <img src={require('../assets/img/pyaterochka.svg')} />
                                            </div>
                                            <div className="shops__item-date">
                                                01.11-06.12
                                            </div>
                                            <div className="arrow">
                                                <svg viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M27.4596 5.45962C27.7135 5.20578 27.7135 4.79422 27.4596 4.54038L23.323 0.403805C23.0692 0.149964 22.6576 0.149964 22.4038 0.403805C22.15 0.657646 22.15 1.0692 22.4038 1.32304L26.0808 5L22.4038 8.67695C22.15 8.93079 22.15 9.34235 22.4038 9.59619C22.6576 9.85003 23.0692 9.85003 23.323 9.59619L27.4596 5.45962ZM2.84124e-08 5.65L27 5.65L27 4.35L-2.84124e-08 4.35L2.84124e-08 5.65Z" fill="#A7E5ED"/>
                                                </svg>
                                            </div>
                                        </a>
                                    </div>*/}
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 shops__item shops__item-utkonos">
                    <a
                      onClick={() => {
                        this.modalClose();
                        this.props.runWidget('utkonos');
                      }}
                      className="shops__item-block">
                      <div className="shops__item-logo d-flex align-items-center justify-content-center"></div>
                      <div className="shops__item-date">18.11-15.12</div>
                      <div className="arrow">
                        <svg viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M27.4596 5.45962C27.7135 5.20578 27.7135 4.79422 27.4596 4.54038L23.323 0.403805C23.0692 0.149964 22.6576 0.149964 22.4038 0.403805C22.15 0.657646 22.15 1.0692 22.4038 1.32304L26.0808 5L22.4038 8.67695C22.15 8.93079 22.15 9.34235 22.4038 9.59619C22.6576 9.85003 23.0692 9.85003 23.323 9.59619L27.4596 5.45962ZM2.84124e-08 5.65L27 5.65L27 4.35L-2.84124e-08 4.35L2.84124e-08 5.65Z"
                            fill="#A7E5ED"
                          />
                        </svg>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 shops__item shops__item-perekrestok">
                    <a
                      onClick={() => {
                        this.modalClose();
                        this.props.runWidget('perekrestok');
                      }}
                      className="shops__item-block">
                      <div className="shops__item-logo d-flex align-items-center justify-content-center"></div>
                      <div className="shops__item-date">23.11-27.12</div>
                      <div className="arrow">
                        <svg viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M27.4596 5.45962C27.7135 5.20578 27.7135 4.79422 27.4596 4.54038L23.323 0.403805C23.0692 0.149964 22.6576 0.149964 22.4038 0.403805C22.15 0.657646 22.15 1.0692 22.4038 1.32304L26.0808 5L22.4038 8.67695C22.15 8.93079 22.15 9.34235 22.4038 9.59619C22.6576 9.85003 23.0692 9.85003 23.323 9.59619L27.4596 5.45962ZM2.84124e-08 5.65L27 5.65L27 4.35L-2.84124e-08 4.35L2.84124e-08 5.65Z"
                            fill="#A7E5ED"
                          />
                        </svg>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 shops__item shops__item-sbermarket">
                    <a
                      onClick={() => {
                        this.modalClose();
                        this.props.runWidget('sbermarket');
                      }}
                      className="shops__item-block">
                      <div className="shops__item-logo d-flex align-items-center justify-content-center"></div>
                      <div className="shops__item-date">27.11-10.12</div>
                      <div className="arrow">
                        <svg viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M27.4596 5.45962C27.7135 5.20578 27.7135 4.79422 27.4596 4.54038L23.323 0.403805C23.0692 0.149964 22.6576 0.149964 22.4038 0.403805C22.15 0.657646 22.15 1.0692 22.4038 1.32304L26.0808 5L22.4038 8.67695C22.15 8.93079 22.15 9.34235 22.4038 9.59619C22.6576 9.85003 23.0692 9.85003 23.323 9.59619L27.4596 5.45962ZM2.84124e-08 5.65L27 5.65L27 4.35L-2.84124e-08 4.35L2.84124e-08 5.65Z"
                            fill="#A7E5ED"
                          />
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-show={this.state.modal__lk}
          className="modal__custom-small modal__custom modal__custom-big modal__custom-lk">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={this.modalClose}></div>

            <div className="modal__box-content">
              <div className="modal__box-header d-flex align-items-end justify-content-between">
                <div className="modal__box-title">Личный кабинет</div>
                <div className="modal-title-min">Для участия в акции необходимо:</div>
              </div>
              <div className=" modal__box-content">
                <div className="d-flex d-col-12 d-f-row m-f-column btn__list">
                  <div className="d-col-6 m-col-12 text-left btn__item">
                    <div
                      onClick={() => {
                        this.loginShow();
                        window.dataLayer.push({
                          event: 'gaEvent',
                          eventCategory: 'internal',
                          eventAction: 'click',
                          eventLabel: 'auth',
                        });
                      }}
                      className="btn__common btn__common-yellow">
                      <span className="btn__center d-flex align-items-center justify-content-center">
                        Войти
                      </span>
                    </div>
                  </div>
                  <div className="d-flex d-col-6 m-col-12 text-right btn__item">
                    <div
                      onClick={() => {
                        // this.regShow();
                        this.info8Show();
                        // window.dataLayer.push({
                        //   event: 'gaEvent',
                        //   eventCategory: 'internal',
                        //   eventAction: 'click',
                        //   eventLabel: 'register_button',
                        // });
                      }}
                      className="btn__common btn-max">
                      <span className="d-flex align-items-center justify-content-center">
                        Зарегистрироваться
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-show={this.state.modal__reg}
          className="modal__custom modal__custom-big modal__custom-reg">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={() => {
                this.modalClose();
                window.dataLayer.push({
                  event: 'gaEvent',
                  eventCategory: 'internal',
                  eventAction: 'do',
                  eventLabel: 'signup_close',
                });
              }}></div>

            <div className="modal__box-content">
              <div className="modal__box-header d-flex align-items-end justify-content-between">
                <div className="modal__box-title">Регистрация</div>
              </div>
              <div className="modal__form">
                <div className="form__list">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 form__col">
                      <div
                        className={
                          'form__item ' +
                          (this.state.data.reg.nameFocus === true || this.state.data.reg.name !== ''
                            ? 'form-focused'
                            : '')
                        }>
                        <div
                          className={[
                            'form__field ',
                            this.state.errors.reg.name === true ? 'has-error' : '',
                          ].join(' ')}>
                          <input
                            type="name"
                            placeholder="Имя"
                            ref={(name) => {
                              this.nameInput = name;
                            }}
                            value={this.state.data.reg.name}
                            onBlur={() => {
                              let data = this.state.data;
                              data.reg.nameFocus = false;
                              this.setState({ data: data });
                            }}
                            onFocus={() => {
                              let data = this.state.data;
                              let errors = this.state.errors;
                              data.reg.nameFocus = true;
                              errors.reg.name = false;
                              this.setState({
                                data: data,
                                errors: errors,
                              });
                            }}
                            onChange={(name) => {
                              let data = this.state.data;
                              data.reg.name = name.target.value;
                              this.setState({ data: data });
                            }}
                          />
                          {this.renderError('reg', 'name')}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 form__col">
                      <div
                        className={
                          'form__item ' +
                          (this.state.data.reg.surnameFocus === true ||
                          this.state.data.reg.surname !== ''
                            ? 'form-focused'
                            : '')
                        }>
                        <div
                          className={[
                            'form__field ',
                            this.state.errors.reg.surname === true ? 'has-error' : '',
                          ].join(' ')}>
                          <input
                            type="surname"
                            placeholder="Фамилия"
                            ref={(surname) => {
                              this.surnameInput = surname;
                            }}
                            value={this.state.data.reg.surname}
                            onBlur={() => {
                              let data = this.state.data;
                              data.reg.surnameFocus = false;
                              this.setState({ data: data });
                            }}
                            onFocus={() => {
                              let data = this.state.data;
                              let errors = this.state.errors;
                              data.reg.surnameFocus = true;
                              errors.reg.surname = false;
                              this.setState({
                                data: data,
                                errors: errors,
                              });
                            }}
                            onChange={(surname) => {
                              let data = this.state.data;
                              data.reg.surname = surname.target.value;
                              this.setState({ data: data });
                            }}
                          />
                          {this.renderError('reg', 'surname')}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 form__col">
                      <div
                        className={
                          'form__item ' +
                          (this.state.data.reg.phoneFocus === true ||
                          this.state.data.reg.phone !== ''
                            ? 'form-focused'
                            : '')
                        }>
                        <div
                          className={[
                            'form__field ',
                            this.state.errors.reg.phone === true ? 'has-error' : '',
                          ].join(' ')}>
                          <div
                            ref={(phone2) => {
                              this.phone2Input = phone2;
                            }}>
                            <InputMask
                              type="phone"
                              mask="+7(999)999-99-99"
                              placeholder="Телефон"
                              alwaysShowMask={false}
                              value={this.state.data.reg.phone}
                              onBlur={() => {
                                let data = this.state.data;
                                data.reg.phoneFocus = false;
                                this.setState({ data: data });
                              }}
                              onFocus={() => {
                                let data = this.state.data;
                                let errors = this.state.errors;
                                data.reg.phoneFocus = true;
                                errors.reg.phone = false;
                                this.setState({
                                  data: data,
                                  errors: errors,
                                });
                              }}
                              onChange={(phone) => {
                                let data = this.state.data;
                                data.reg.phone = phone.target.value;
                                this.setState({ data: data });
                              }}
                            />
                            {this.renderError('reg', 'phone')}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          'form__item ' +
                          (this.state.data.reg.emailFocus === true ||
                          this.state.data.reg.email !== ''
                            ? 'form-focused'
                            : '')
                        }>
                        <div
                          className={[
                            'form__field',
                            this.state.errors.reg.email === true ? 'has-error' : '',
                          ].join(' ')}>
                          <input
                            placeholder="e-mail"
                            ref={(email3) => {
                              this.email3Input = email3;
                            }}
                            type="text"
                            autoComplete="username"
                            onFocus={() => {
                              let data = this.state.data;
                              data.reg.emailFocus = true;
                              let errors = this.state.errors;
                              errors.reg.email = false;
                              this.setState({ errors: errors, data: data });
                            }}
                            onChange={(email) => {
                              let data = this.state.data;
                              data.reg.email = email.target.value;
                              this.setState({ data: data });
                            }}
                            onBlur={() => {
                              let data = this.state.data;
                              data.reg.emailFocus = false;
                              this.setState({ data: data });
                            }}
                            value={this.state.data.reg.email}
                          />
                          {this.renderError('reg', 'email')}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 form__col">
                      <div
                        className={
                          'form__item ' +
                          (this.state.data.reg.promocode2Focus === true ||
                          this.state.data.reg.promocode2 !== ''
                            ? 'form-focused'
                            : '')
                        }>
                        <div
                          className={[
                            'form__field ',
                            this.state.errors.reg.promocode2 === true ? 'has-error' : '',
                          ].join(' ')}>
                          <input
                            type="text"
                            placeholder="Промокод"
                            ref={(promocode2) => {
                              this.promocode2Input = promocode2;
                            }}
                            value={this.state.data.reg.promocode2}
                            onBlur={() => {
                              let data = this.state.data;
                              data.reg.promocode2Focus = false;
                              this.setState({ data: data });
                            }}
                            onFocus={() => {
                              let data = this.state.data;
                              let errors = this.state.errors;
                              data.reg.promocode2Focus = true;
                              errors.reg.promocode2 = false;
                              this.setState({
                                data: data,
                                errors: errors,
                              });
                            }}
                            onChange={(promocode2) => {
                              let data = this.state.data;
                              data.reg.promocode2 = promocode2.target.value;
                              this.setState({ data: data });
                            }}
                          />
                          {this.renderError('reg', 'promocode2')}
                        </div>
                      </div>
                    </div>
                    <div className="d-col-6 m-col-12">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 form__col">
                        <div className="checkbox__list">
                          <div
                            className={
                              'checkbox__item d-flex ' +
                              (this.state.errors.reg.agree1 === true ? 'has-error' : '')
                            }>
                            <label>
                              <input
                                type="checkbox"
                                name="agree1"
                                onChange={(agree1) => {
                                  let data = this.state.data;
                                  let errors = this.state.errors;
                                  data.reg.agree1 = agree1.target.checked;
                                  errors.reg.agree1 = false;
                                  this.setState({ data: data });
                                  this.setState({ errors: errors });
                                }}
                                checked={this.state.data.reg.agree1}
                              />
                              <div className="checkbox__decor"></div>
                              <div className="checkbox__title">
                                Я согласен с{' '}
                                <a href={this.props.link.pravila + '.pdf'} target="_BLANK">
                                  правилами акции
                                </a>
                              </div>
                            </label>
                            {this.renderError('reg', 'agree1')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-col-6 m-col-12">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 form__col">
                        <div className="checkbox__list">
                          <div
                            className={
                              'checkbox__item d-flex ' +
                              (this.state.errors.reg.agree2 === true ? 'has-error' : '')
                            }>
                            <label>
                              <input
                                type="checkbox"
                                name="agree2"
                                onChange={(agree2) => {
                                  let data = this.state.data;
                                  let errors = this.state.errors;
                                  data.reg.agree2 = agree2.target.checked;
                                  errors.reg.agree2 = false;
                                  this.setState({ data: data });
                                  this.setState({ errors: errors });
                                }}
                                checked={this.state.data.reg.agree2}
                              />
                              <div className="checkbox__decor"></div>
                              <div className="checkbox__title">
                                Я даю согласие на обработку моих{' '}
                                <a href={this.props.link.agreement} target="_BLANK">
                                  персональных данных
                                </a>
                              </div>
                            </label>
                            {this.renderError('reg', 'agree2')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-col-12 m-col-12">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 form__col">
                        <div className="checkbox__list">
                          <div
                            className={
                              'checkbox__item d-flex ' +
                              (this.state.errors.reg.agree3 === true ? 'has-error' : '')
                            }>
                            <label>
                              <input
                                type="checkbox"
                                name="agree3"
                                onChange={(agree3) => {
                                  let data = this.state.data;
                                  let errors = this.state.errors;
                                  data.reg.agree3 = agree3.target.checked;
                                  errors.reg.agree3 = false;
                                  this.setState({ data: data });
                                  this.setState({ errors: errors });
                                }}
                                checked={this.state.data.reg.agree3}
                              />
                              <div className="checkbox__decor"></div>
                              <div className="checkbox__title">
                                Я согласен(на) на получение персональных предложений от партнера
                              </div>
                            </label>
                            {this.renderError('reg', 'agree3')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 form__col">
                      <div className="form__item form__item-actions d-flex align-items-center justify-content-start">
                        <div
                          className={
                            'form__action d-flex justify-content-center ' +
                            (this.state.loading === true ? 'disabled' : '')
                          }>
                          <div
                            onClick={() => {
                              this.submitReg();
                            }}
                            className="btn__common btn-max btn__center m-arrow-none">
                            <span>Зарегистрироваться</span>
                            <div className="loader">
                              <img src={loader}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="modal__box-info d-flex align-items-center justify-content-center">
                            <i className="info__icon"></i>
                            <span>Все поля обязательны для заполнения</span>
                        </div>*/}
          </div>
        </div>
        <div
          data-show={this.state.modal__addData}
          className="modal__custom modal__custom-big modal__custom-reg">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={() => {
                this.modalClose();
                // window.dataLayer.push({
                //   event: 'gaEvent',
                //   eventCategory: 'internal',
                //   eventAction: 'click',
                //   eventLabel: 'close_registration_form',
                // });
              }}></div>

            <div className="modal__box-content">
              <div className="modal__box-header d-flex align-items-end justify-content-between">
                <div className="modal__box-title">Введите данные</div>
              </div>
              <div className="modal__form">
                <div className="form__list">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 form__col">
                      <div
                        className={
                          'form__item ' +
                          (this.state.data.reg.nameFocus === true || this.state.data.reg.name !== ''
                            ? 'form-focused'
                            : '')
                        }>
                        <div
                          className={[
                            'form__field ',
                            this.state.errors.reg.name === true ? 'has-error' : '',
                          ].join(' ')}>
                          <input
                            type="name"
                            placeholder="ФИО"
                            ref={(name) => {
                              this.nameInput = name;
                            }}
                            value={this.state.data.reg.name}
                            onBlur={() => {
                              let data = this.state.data;
                              data.reg.nameFocus = false;
                              this.setState({ data: data });
                            }}
                            onFocus={() => {
                              let data = this.state.data;
                              let errors = this.state.errors;
                              data.reg.nameFocus = true;
                              errors.reg.name = false;
                              this.setState({
                                data: data,
                                errors: errors,
                              });
                            }}
                            onChange={(name) => {
                              let data = this.state.data;
                              data.reg.name = name.target.value;
                              this.setState({ data: data });
                            }}
                          />
                          {this.renderError('reg', 'name')}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 form__col">
                      <div
                        className={
                          'form__item ' +
                          (this.state.data.reg.phoneFocus === true ||
                          this.state.data.reg.phone !== ''
                            ? 'form-focused'
                            : '')
                        }>
                        <div
                          className={[
                            'form__field ',
                            this.state.errors.reg.phone === true ? 'has-error' : '',
                          ].join(' ')}>
                          <div
                            ref={(phone2) => {
                              this.phone2Input = phone2;
                            }}>
                            <InputMask
                              type="phone"
                              mask="+7(999)999-99-99"
                              placeholder="Телефон"
                              alwaysShowMask={false}
                              value={this.state.data.reg.phone}
                              onBlur={() => {
                                let data = this.state.data;
                                data.reg.phoneFocus = false;
                                this.setState({ data: data });
                              }}
                              onFocus={() => {
                                let data = this.state.data;
                                let errors = this.state.errors;
                                data.reg.phoneFocus = true;
                                errors.reg.phone = false;
                                this.setState({
                                  data: data,
                                  errors: errors,
                                });
                              }}
                              onChange={(phone) => {
                                let data = this.state.data;
                                data.reg.phone = phone.target.value;
                                this.setState({ data: data });
                              }}
                            />
                            {this.renderError('reg', 'phone')}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          'form__item ' +
                          (this.state.data.reg.emailFocus === true ||
                          this.state.data.reg.email !== ''
                            ? 'form-focused'
                            : '')
                        }>
                        <div
                          className={[
                            'form__field',
                            this.state.errors.reg.email === true ? 'has-error' : '',
                          ].join(' ')}>
                          <input
                            placeholder="e-mail"
                            ref={(email3) => {
                              this.email3Input = email3;
                            }}
                            type="text"
                            autoComplete="username"
                            onFocus={() => {
                              let data = this.state.data;
                              data.reg.emailFocus = true;
                              let errors = this.state.errors;
                              errors.reg.email = false;
                              this.setState({ errors: errors, data: data });
                            }}
                            onChange={(email) => {
                              let data = this.state.data;
                              data.reg.email = email.target.value;
                              this.setState({ data: data });
                            }}
                            onBlur={() => {
                              let data = this.state.data;
                              data.reg.emailFocus = false;
                              this.setState({ data: data });
                            }}
                            value={this.state.data.reg.email}
                          />
                          {this.renderError('reg', 'email')}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 form__col">
                      <div
                        className={
                          'form__item ' +
                          (this.state.data.reg.cityFocus === true || this.state.data.reg.city !== ''
                            ? 'form-focused'
                            : '')
                        }>
                        <div
                          className={[
                            'form__field ',
                            this.state.errors.reg.city === true ? 'has-error' : '',
                          ].join(' ')}>
                          <input
                            type="text"
                            placeholder="Город"
                            ref={(city) => {
                              this.cityInput = city;
                            }}
                            value={this.state.data.reg.city}
                            onBlur={() => {
                              let data = this.state.data;
                              data.reg.cityFocus = false;
                              this.setState({ data: data });
                            }}
                            onFocus={() => {
                              let data = this.state.data;
                              let errors = this.state.errors;
                              data.reg.cityFocus = true;
                              errors.reg.city = false;
                              this.setState({
                                data: data,
                                errors: errors,
                              });
                            }}
                            onChange={(city) => {
                              let data = this.state.data;
                              data.reg.city = city.target.value;
                              this.setState({ data: data });
                            }}
                          />
                          {this.renderError('reg', 'city')}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 form__col">
                      <div
                        className={
                          'form__item ' +
                          (this.state.data.reg.streetFocus === true ||
                          this.state.data.reg.street !== ''
                            ? 'form-focused'
                            : '')
                        }>
                        <div
                          className={[
                            'form__field ',
                            this.state.errors.reg.street === true ? 'has-error' : '',
                          ].join(' ')}>
                          <input
                            type="text"
                            placeholder="Улица"
                            ref={(street) => {
                              this.streetInput = street;
                            }}
                            value={this.state.data.reg.street}
                            onBlur={() => {
                              let data = this.state.data;
                              data.reg.streetFocus = false;
                              this.setState({ data: data });
                            }}
                            onFocus={() => {
                              let data = this.state.data;
                              let errors = this.state.errors;
                              data.reg.streetFocus = true;
                              errors.reg.street = false;
                              this.setState({
                                data: data,
                                errors: errors,
                              });
                            }}
                            onChange={(street) => {
                              let data = this.state.data;
                              data.reg.street = street.target.value;
                              this.setState({ data: data });
                            }}
                          />
                          {this.renderError('reg', 'street')}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex d-col-12 m-text-center d-f-justify-space-between m-f-justify-center d-f-row m-f-column d-f-div-max">
                      <div className="form__item-actions d-flex align-items-center justify-content-center m-col-12">
                        <div
                          className={
                            'form__item ' +
                            (this.state.data.reg.homeFocus === true ||
                            this.state.data.reg.home !== ''
                              ? 'form-focused'
                              : '')
                          }>
                          <div
                            className={[
                              'form__field ',
                              this.state.errors.reg.home === true ? 'has-error' : '',
                            ].join(' ')}>
                            <input
                              type="text"
                              placeholder="Дом"
                              ref={(home) => {
                                this.homeInput = home;
                              }}
                              value={this.state.data.reg.home}
                              onBlur={() => {
                                let data = this.state.data;
                                data.reg.homeFocus = false;
                                this.setState({ data: data });
                              }}
                              onFocus={() => {
                                let data = this.state.data;
                                let errors = this.state.errors;
                                data.reg.homeFocus = true;
                                errors.reg.home = false;
                                this.setState({
                                  data: data,
                                  errors: errors,
                                });
                              }}
                              onChange={(home) => {
                                let data = this.state.data;
                                data.reg.home = home.target.value;
                                this.setState({ data: data });
                              }}
                            />
                            {this.renderError('reg', 'home')}
                          </div>
                        </div>
                      </div>
                      <div className="form__item-actions d-flex align-items-center justify-content-center m-col-12">
                        <div
                          className={
                            'form__item ' +
                            (this.state.data.reg.flatFocus === true ||
                            this.state.data.reg.flat !== ''
                              ? 'form-focused'
                              : '')
                          }>
                          <div
                            className={[
                              'form__field ',
                              this.state.errors.reg.flat === true ? 'has-error' : '',
                            ].join(' ')}>
                            <input
                              type="text"
                              placeholder="Квартира"
                              ref={(flat) => {
                                this.flatInput = flat;
                              }}
                              value={this.state.data.reg.flat}
                              onBlur={() => {
                                let data = this.state.data;
                                data.reg.flatFocus = false;
                                this.setState({ data: data });
                              }}
                              onFocus={() => {
                                let data = this.state.data;
                                let errors = this.state.errors;
                                data.reg.flatFocus = true;
                                errors.reg.flat = false;
                                this.setState({
                                  data: data,
                                  errors: errors,
                                });
                              }}
                              onChange={(flat) => {
                                let data = this.state.data;
                                data.reg.flat = flat.target.value;
                                this.setState({ data: data });
                              }}
                            />
                            {this.renderError('reg', 'flat')}
                          </div>
                        </div>
                      </div>
                      <div className="form__item-actions d-flex align-items-center justify-content-center m-col-12">
                        <div
                          className={
                            'form__item ' +
                            (this.state.data.reg.postalIndexFocus === true ||
                            this.state.data.reg.postalIndex !== ''
                              ? 'form-focused'
                              : '')
                          }>
                          <div
                            className={[
                              'form__field ',
                              this.state.errors.reg.postalIndex === true ? 'has-error' : '',
                            ].join(' ')}>
                            <input
                              type="text"
                              placeholder="Индекс"
                              ref={(postalIndex) => {
                                this.postalIndexInput = postalIndex;
                              }}
                              value={this.state.data.reg.postalIndex}
                              onBlur={() => {
                                let data = this.state.data;
                                data.reg.postalIndexFocus = false;
                                this.setState({ data: data });
                              }}
                              onFocus={() => {
                                let data = this.state.data;
                                let errors = this.state.errors;
                                data.reg.postalIndexFocus = true;
                                errors.reg.postalIndex = false;
                                this.setState({
                                  data: data,
                                  errors: errors,
                                });
                              }}
                              onChange={(postalIndex) => {
                                let data = this.state.data;
                                data.reg.postalIndex = postalIndex.target.value;
                                this.setState({ data: data });
                              }}
                            />
                            {this.renderError('reg', 'postalIndex')}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form__col">
                      <div className="form__item">
                        <div className="form__info">
                          * Подарок будет доставлен в ближайшее отделение Почты РФ по указанному
                          адресу
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 form__col">
                      <div className="form__item form__item-actions d-flex align-items-center justify-content-center">
                        <div
                          className={
                            'form__action d-flex justify-content-center ' +
                            (this.state.loading === true ? 'disabled' : '')
                          }>
                          <div
                            onClick={() => {
                              this.submitAddData();
                            }}
                            className="btn__common btn__center text-center">
                            <span>Сохранить</span>
                            <div className="loader">
                              <img src={loader}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="modal__box-info d-flex align-items-center justify-content-center">
                            <i className="info__icon"></i>
                            <span>Все поля обязательны для заполнения</span>
                        </div>*/}
          </div>
        </div>
        <div
          data-show={this.state.modal__change_data}
          className="modal__custom modal__custom-big modal__custom-reg">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={this.modalClose}></div>

            <div className="modal__box-content">
              <div className="modal__box-header d-flex align-items-end justify-content-between">
                <div className="modal__box-title">Личный кабинет</div>
                <div className="modal-title-min">Редактировать данные</div>
              </div>
              <div className="modal__form">
                <div className="form__list">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 form__col">
                      <div
                        className={
                          'form__item ' +
                          (this.state.data.reg.numberCartFocus === true ||
                          this.state.data.reg.numberCart !== ''
                            ? 'form-focused'
                            : '')
                        }>
                        <div
                          className={[
                            'form__field ',
                            this.state.errors.reg.numberCart === true ? 'has-error' : '',
                          ].join(' ')}>
                          <div
                            className="form__label"
                            onClick={() => {
                              this.numberCartInput.querySelector('input').focus();
                            }}>
                            Номер карты лояльности
                          </div>
                          <div
                            ref={(numberCart) => {
                              this.numberCartInput = numberCart;
                            }}>
                            <InputMask
                              type="text"
                              mask="9999999999999999"
                              placeholder="-------------------"
                              alwaysShowMask={false}
                              value={this.state.data.reg.numberCart}
                              onBlur={() => {
                                let data = this.state.data;
                                data.reg.numberCartFocus = false;
                                this.setState({ data: data });
                              }}
                              onFocus={() => {
                                let data = this.state.data;
                                let errors = this.state.errors;
                                data.reg.numberCartFocus = true;
                                errors.reg.numberCart = false;
                                this.setState({
                                  data: data,
                                  errors: errors,
                                });
                              }}
                              onChange={(numberCart) => {
                                let data = this.state.data;
                                data.reg.numberCart = numberCart.target.value;
                                this.setState({ data: data });
                              }}
                            />
                            {this.renderError('reg', 'numberCart')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 form__col">
                      <div className="form__item form__item-actions d-flex align-items-center justify-content-start">
                        <div
                          className={
                            'form__action d-flex justify-content-center ' +
                            (this.state.loading === true ? 'disabled' : '')
                          }>
                          <div
                            onClick={() => {
                              this.submitChangeData();
                            }}
                            className="btn__custom btn__custom-dark d-flex align-items-center justify-content-center">
                            <span>Сохранить изменения</span>
                            <div className="loader">
                              <img src={loader}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="modal__box-info d-flex align-items-center justify-content-center">
                            <i className="info__icon"></i>
                            <span>Все поля обязательны для заполнения</span>
                        </div>*/}
          </div>
        </div>
        <div
          data-show={this.state.modal__login}
          className="modal__custom modal__custom-small modal__login">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={this.modalClose}></div>

            <div className="modal__box-content">
              <div className="modal__box-header d-flex align-items-end justify-content-between">
                <div className="modal__box-title">Авторизация</div>
              </div>
              <div className="modal__form">
                <div className="form__list">
                  <form>
                    <div
                      className={
                        'form__item ' +
                        (this.state.data.login.emailFocus === true ||
                        this.state.data.login.email !== ''
                          ? 'form-focused'
                          : '')
                      }>
                      <div
                        className={[
                          'form__field',
                          this.state.errors.login.email === true ? 'has-error' : '',
                        ].join(' ')}>
                        <input
                          placeholder="E-mail"
                          ref={(email1) => {
                            this.email1Input = email1;
                          }}
                          type="text"
                          autoComplete="username"
                          onFocus={() => {
                            let data = this.state.data;
                            data.login.emailFocus = true;
                            let errors = this.state.errors;
                            errors.login.email = false;
                            // data.login.password = document.getElementById("password").target.value;
                            // console.log(this.state.data.login.password);
                            this.setState({ errors: errors, data: data });
                          }}
                          onChange={(email) => {
                            let data = this.state.data;
                            data.login.email = email.target.value;
                            this.setState({ data: data });
                          }}
                          onBlur={() => {
                            let data = this.state.data;
                            data.login.emailFocus = false;
                            this.setState({ data: data });
                          }}
                          value={this.state.data.login.email}
                        />
                        {this.renderError('login', 'email')}
                      </div>
                    </div>
                    <div
                      className={
                        'form__item ' +
                        (this.state.data.login.passwordFocus === true ||
                        this.state.data.login.password !== ''
                          ? 'form-focused'
                          : '')
                      }>
                      <div
                        className={[
                          'form__field form__field-pass ',
                          this.state.errors.login.password === true ? 'has-error' : '',
                        ].join(' ')}>
                        <input
                          placeholder="Пароль"
                          ref={(pass1) => {
                            this.pass1Input = pass1;
                          }}
                          id="password "
                          type="password"
                          onFocus={(password) => {
                            let data = this.state.data;
                            data.login.passwordFocus = true;
                            data.login.password = password.target.value;
                            let errors = this.state.errors;
                            errors.login.password = false;
                            this.setState({ errors: errors, data: data });
                          }}
                          onChange={(password) => {
                            let data = this.state.data;
                            data.login.password = password.target.value;
                            this.setState({ data: data });
                          }}
                          onBlur={(password) => {
                            let data = this.state.data;
                            data.login.passwordFocus = false;
                            data.login.password = password.target.value;
                            this.setState({ data: data });
                          }}
                          value={this.state.data.login.password}
                        />

                        <i
                          className={
                            'icon__eye ' + (this.state.passType === 'text' ? 'active' : '')
                          }
                          onClick={() => {
                            if (this.state.passType === 'text') {
                              this.setState({
                                passType: 'password',
                              });
                            } else {
                              this.setState({
                                passType: 'text',
                              });
                            }
                          }}></i>
                        {this.renderError('login', 'password')}
                      </div>
                    </div>
                    <div className="d-flex d-col-12 d-f-row m-f-column">
                      <div className="text-left m-text-left d-col-6 m-col-12 form__col">
                        <div className="checkbox__list">
                          <div
                            className={
                              'checkbox__item m-text-left d-flex ' +
                              (this.state.errors.login.agree1 === true ? 'has-error' : '')
                            }>
                            <label className="text-center m-text-left">
                              <input
                                type="checkbox"
                                name="agree1"
                                onChange={(agree1) => {
                                  let data = this.state.data;
                                  let errors = this.state.errors;
                                  data.login.agree1 = agree1.target.checked;
                                  errors.login.agree1 = false;
                                  this.setState({ data: data });
                                  this.setState({ errors: errors });
                                }}
                              />
                              <div className="checkbox__decor"></div>
                              <div className="checkbox__title">Запомнить меня</div>
                            </label>
                            {this.renderError('login', 'agree1')}
                          </div>
                        </div>
                      </div>
                      <div className="text-right m-text-left d-col-6 m-col-12 form__link">
                        <a
                          onClick={() => {
                            this.recoveryShow();
                            // window.dataLayer.push({
                            //   event: 'gaEvent',
                            //   eventCategory: 'internal',
                            //   eventAction: 'click',
                            //   eventLabel: 'remind_password',
                            // });
                          }}>
                          забыли пароль?
                        </a>
                      </div>
                    </div>
                    <div className="d-flex d-col-12 m-text-center d-f-justify-space-between m-f-justify-center d-f-row m-f-column">
                      <div className="form__item form__item-actions d-flex align-items-center justify-content-center">
                        <div
                          className={
                            'form__action d-flex justify-content-center m-text-left ' +
                            (this.state.loading === true ? 'disabled' : '')
                          }>
                          <div
                            onClick={() => {
                              this.submitLogin();
                              let data = this.state.data;
                              // data.login.passwordFocus = true;
                              // data.login.password = password.target.value;
                              window.dataLayer.push({
                                event: 'gaEvent',
                                eventCategory: 'internal',
                                eventAction: 'click',
                                eventLabel: 'auth',
                              });
                            }}
                            className="btn__custom btn__custom-dark d-flex align-items-center justify-content-center m-text-left">
                            <span>Войти</span>
                            <div className="loader">
                              <img src={loader}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form__item form__item-actions d-flex align-items-center justify-content-center">
                        <div
                          className={
                            'form__action d-flex justify-content-center ' +
                            (this.state.loading === true ? 'disabled' : '')
                          }>
                          <div
                            onClick={() => {
                              // this.regShow();
                              this.info8Show();
                              // this.info8Show();
                              window._tmr.push({
                                type: 'reachGoal',
                                id: 3259179,
                                goal: 'registration',
                              });
                            }}
                            className="btn__custom btn__custom-dark d-flex align-items-center justify-content-center m-text-left">
                            <span>РЕГИСТРАЦИЯ</span>
                            <div className="loader">
                              <img src={loader}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-show={this.state.modal__change} className="modal__custom modal__custom-small">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={this.modalClose}></div>

            <div className="modal__box-content">
              <div className="modal__box-header d-flex align-items-end justify-content-between">
                <div className="modal__box-title">Смена пароля</div>
              </div>
              <div className="modal__form">
                <div className="form__list">
                  <div
                    className={
                      'form__item ' +
                      (this.state.data.change.pass1Focus === true ||
                      this.state.data.change.pass1 !== ''
                        ? 'form-focused'
                        : '')
                    }>
                    <div
                      className={[
                        'form__field form__field-pass ',
                        this.state.errors.change.pass1 === true ? 'has-error' : '',
                      ].join(' ')}>
                      <input
                        ref={(pass1) => {
                          this.pass1Input = pass1;
                        }}
                        placeholder="Старый Пароль"
                        type={this.state.passType}
                        autoComplete="current-password"
                        onFocus={() => {
                          let data = this.state.data;
                          data.change.pass1Focus = true;
                          let errors = this.state.errors;
                          errors.change.pass1 = false;
                          this.setState({ errors: errors, data: data });
                        }}
                        onChange={(pass1) => {
                          let data = this.state.data;
                          data.change.pass1 = pass1.target.value;
                          this.setState({ data: data });
                        }}
                        onBlur={() => {
                          let data = this.state.data;
                          data.change.pass1Focus = false;
                          this.setState({ data: data });
                        }}
                        value={this.state.data.change.pass1}
                      />
                      {this.renderError('change', 'pass1')}
                    </div>
                  </div>
                  <div
                    className={
                      'form__item ' +
                      (this.state.data.change.pass2Focus === true ||
                      this.state.data.change.pass2 !== ''
                        ? 'form-focused'
                        : '')
                    }>
                    <div
                      className={[
                        'form__field form__field-pass ',
                        this.state.errors.change.pass2 === true ? 'has-error' : '',
                      ].join(' ')}>
                      <input
                        ref={(pass2) => {
                          this.pass2Input = pass2;
                        }}
                        placeholder="Новый Пароль"
                        type={this.state.passType}
                        autoComplete="current-password"
                        onFocus={() => {
                          let data = this.state.data;
                          data.change.pass2Focus = true;
                          let errors = this.state.errors;
                          errors.change.pass2 = false;
                          this.setState({ errors: errors, data: data });
                        }}
                        onChange={(pass2) => {
                          let data = this.state.data;
                          data.change.pass2 = pass2.target.value;
                          this.setState({ data: data });
                        }}
                        onBlur={() => {
                          let data = this.state.data;
                          data.change.pass2Focus = false;
                          this.setState({ data: data });
                        }}
                      />
                      {this.renderError('change', 'pass2')}
                    </div>
                  </div>
                  <div
                    className={
                      'form__item ' +
                      (this.state.data.change.pass3Focus === true ||
                      this.state.data.change.pass3 !== ''
                        ? 'form-focused'
                        : '')
                    }>
                    <div
                      className={[
                        'form__field form__field-pass ',
                        this.state.errors.change.pass3 === true ? 'has-error' : '',
                      ].join(' ')}>
                      <input
                        ref={(pass3) => {
                          this.pass3Input = pass3;
                        }}
                        placeholder="Повторите Пароль"
                        type={this.state.passType}
                        autoComplete="current-password"
                        onFocus={() => {
                          let data = this.state.data;
                          data.change.pass3Focus = true;
                          let errors = this.state.errors;
                          errors.change.pass3 = false;
                          this.setState({ errors: errors, data: data });
                        }}
                        onChange={(pass3) => {
                          let data = this.state.data;
                          data.change.pass3 = pass3.target.value;
                          this.setState({ data: data });
                        }}
                        onBlur={() => {
                          let data = this.state.data;
                          data.change.pass3Focus = false;
                          this.setState({ data: data });
                        }}
                      />
                      {this.renderError('change', 'pass3')}
                    </div>
                  </div>
                  <div className="d-flex d-col-12 m-text-center d-f-justify-space-between m-f-justify-center d-f-row m-f-column">
                    <div className="text-left m-text-left d-col-6 m-col-12 form__col d-flex d-aling-center">
                      <div className="checkbox__list">
                        <div
                          className={
                            'checkbox__item m-text-left d-flex m-b-0 ' +
                            (this.state.errors.login.agree1 === true ? 'has-error' : '')
                          }>
                          <label className="text-center m-text-left">
                            <input
                              type="checkbox"
                              name="agree1"
                              onChange={(agree1) => {
                                let data = this.state.data;
                                let errors = this.state.errors;
                                data.login.agree1 = agree1.target.checked;
                                errors.login.agree1 = false;
                                this.setState({ data: data });
                                this.setState({ errors: errors });
                              }}
                            />
                            <div className="checkbox__decor"></div>
                            <div className="checkbox__title">Запомнить пароль</div>
                          </label>
                          {this.renderError('login', 'agree1')}
                        </div>
                      </div>
                    </div>
                    <div className="form__item form__item-actions d-flex align-items-center justify-content-center m-b-0">
                      <div
                        className={
                          'form__action d-flex justify-content-center ' +
                          (this.state.loading === true ? 'disabled' : '')
                        }>
                        <div
                          onClick={() => {
                            this.submitChange();
                          }}
                          className="btn__custom btn__custom-dark d-flex align-items-center justify-content-center m-t-0">
                          <span>Сохранить</span>
                          <div className="loader">
                            <img src={loader}></img>
                          </div>
                        </div>
                        {this.renderError('change', 'error')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-show={this.state.modal__recovery} className="modal__custom modal__custom-small">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={this.modalClose}></div>

            <div className="modal__box-content">
              <div className="modal__box-header d-flex align-items-end justify-content-between">
                <div className="modal__box-title">Личный кабинет</div>
              </div>
              <div className="modal__form">
                <div className="form__list">
                  <div
                    className={
                      'form__item ' +
                      (this.state.data.recovery.emailFocus === true ||
                      this.state.data.recovery.email !== ''
                        ? 'form-focused'
                        : '')
                    }>
                    <div className="form__label">Восстановить пароль</div>
                    <div
                      className={[
                        'form__field',
                        this.state.errors.recovery.email === true ? 'has-error' : '',
                      ].join(' ')}>
                      <input
                        placeholder="E-mail"
                        ref={(email2) => {
                          this.email2Input = email2;
                        }}
                        type="email"
                        autoComplete="username"
                        onFocus={() => {
                          let data = this.state.data;
                          data.recovery.emailFocus = true;
                          let errors = this.state.errors;
                          errors.recovery.email = false;
                          this.setState({ errors: errors, data: data });
                        }}
                        onChange={(email) => {
                          let data = this.state.data;
                          data.recovery.email = email.target.value;
                          this.setState({ data: data });
                        }}
                        onBlur={() => {
                          let data = this.state.data;
                          data.recovery.emailFocus = false;
                          this.setState({ data: data });
                        }}
                      />
                      {this.renderError('recovery', 'email')}
                    </div>
                  </div>
                  <div className="form__item form__item-actions d-flex align-items-center justify-content-center">
                    <div
                      className={
                        'form__action d-flex justify-content-center ' +
                        (this.state.loading === true ? 'disabled' : '')
                      }>
                      <div
                        onClick={() => {
                          this.submitRecovery();
                        }}
                        className="btn__custom btn__custom-dark d-flex align-items-center justify-content-center">
                        <span>Отправить</span>
                        <div className="loader">
                          <img src={loader}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-show={this.state.modal__login__pass}
          className="modal__custom modal__custom-small">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={this.modalClose}></div>

            <div className="modal__box-content">
              <div className="modal__box-header d-flex align-items-end justify-content-between">
                <div className="modal__box-title">Вход на сайт</div>
              </div>
              <div className="modal__form">
                <div className="form__list">
                  <div
                    className={
                      'form__item ' +
                      (this.state.data.recovery.emailFocus === true ||
                      this.state.data.recovery.email !== ''
                        ? 'form-focused'
                        : '')
                    }>
                    <div className="form__label">
                      Пароль отправлен сообщением на указанный E-mail
                    </div>
                    <div
                      className={
                        'form__item ' +
                        (this.state.data.login.passwordFocus === true ||
                        this.state.data.login.password !== ''
                          ? 'form-focused'
                          : '')
                      }>
                      <div
                        className={[
                          'form__field form__field-pass ',
                          this.state.errors.login.password === true ? 'has-error' : '',
                        ].join(' ')}>
                        <input
                          placeholder="Пароль"
                          ref={(pass1) => {
                            this.pass1Input = pass1;
                          }}
                          id="password "
                          type="password"
                          onFocus={(password) => {
                            let data = this.state.data;
                            data.login.passwordFocus = true;
                            data.login.password = password.target.value;
                            let errors = this.state.errors;
                            errors.login.password = false;
                            this.setState({ errors: errors, data: data });
                          }}
                          onChange={(password) => {
                            let data = this.state.data;
                            data.login.password = password.target.value;
                            this.setState({ data: data });
                          }}
                          onBlur={(password) => {
                            let data = this.state.data;
                            data.login.passwordFocus = false;
                            data.login.password = password.target.value;
                            this.setState({ data: data });
                          }}
                          value={this.state.data.login.password}
                        />

                        <i
                          className={
                            'icon__eye ' + (this.state.passType === 'text' ? 'active' : '')
                          }
                          onClick={() => {
                            if (this.state.passType === 'text') {
                              this.setState({
                                passType: 'password',
                              });
                            } else {
                              this.setState({
                                passType: 'text',
                              });
                            }
                          }}></i>
                        {this.renderError('login', 'password')}
                      </div>
                    </div>
                  </div>
                  <div className="form__item form__item-actions d-flex align-items-center justify-content-center">
                    <div
                      className={
                        'form__action d-flex justify-content-center ' +
                        (this.state.loading === true ? 'disabled' : '')
                      }>
                      <div
                        onClick={() => {
                          let data = this.state.data;
                          data.login.email = localStorage.getItem('login');
                          this.setState({ data: data });
                          this.submitLogin();
                          window.dataLayer.push({
                            event: 'gaEvent',
                            eventCategory: 'internal',
                            eventAction: 'click',
                            eventLabel: 'auth',
                          });
                        }}
                        className="btn__custom btn__custom-dark d-flex align-items-center justify-content-center">
                        <span>Войти</span>
                        <div className="loader">
                          <img src={loader}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-show={this.state.modal__feedback} className="modal__custom modal__custom-small">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={this.modalClose}></div>

            <div className="modal__box-content">
              <div className="modal__box-header d-flex align-items-end justify-content-between">
                <div className="modal__box-title">Обратная связь</div>
                <p>Остались вопросы? Напишите нам!</p>
              </div>
              <div className="modal__form">
                <div className="form__list">
                  <div
                    className={
                      'form__item ' +
                      (this.state.data.feedback.emailFocus === true ||
                      this.state.data.feedback.email !== ''
                        ? 'form-focused'
                        : '')
                    }>
                    <div
                      className={[
                        'form__field',
                        this.state.errors.feedback.email === true ? 'has-error' : '',
                      ].join(' ')}>
                      <div
                        className="form__label"
                        onClick={() => {
                          this.emailInput.focus();
                        }}>
                        E-mail
                      </div>
                      <input
                        placeholder="e-mailexample@mail.com"
                        ref={(email) => {
                          this.emailInput = email;
                        }}
                        type="text"
                        name="email"
                        onFocus={() => {
                          let errors = this.state.errors;
                          let data = this.state.data;
                          data.feedback.emailFocus = true;
                          errors.feedback.email = false;
                          this.setState({ errors: errors, data: data });
                        }}
                        autoComplete="off"
                        value={this.state.data.feedback.email}
                        onChange={(email) => {
                          let data = this.state.data;
                          data.feedback.email = email.target.value;
                          localStorage.setItem('feedbackEmail', email.target.value);
                          this.setState({ data: data });
                        }}
                        onBlur={() => {
                          let data = this.state.data;
                          data.feedback.emailFocus = false;
                          this.setState({ data: data });
                        }}
                      />
                      {this.renderError('feedback', 'email')}
                    </div>
                  </div>
                  <div
                    className={
                      'form__item ' +
                      (this.state.data.feedback.nameFocus === true ||
                      this.state.data.feedback.name !== ''
                        ? 'form-focused'
                        : '')
                    }>
                    <div
                      className={[
                        'form__field',
                        this.state.errors.feedback.name === true ? 'has-error' : '',
                      ].join(' ')}>
                      <div
                        className="form__label"
                        onClick={() => {
                          this.nameInput.focus();
                        }}>
                        ФИО
                      </div>
                      <input
                        placeholder="Иванов Иван Иванович"
                        ref={(name) => {
                          this.nameInput = name;
                        }}
                        type="text"
                        name="name"
                        autoComplete="off"
                        onFocus={() => {
                          let errors = this.state.errors;
                          let data = this.state.data;
                          data.feedback.nameFocus = true;
                          errors.feedback.name = false;
                          this.setState({ errors: errors, data: data });
                        }}
                        value={this.state.data.feedback.name}
                        onChange={(name) => {
                          let data = this.state.data;
                          data.feedback.name = name.target.value;
                          this.setState({ data: data });
                        }}
                        onBlur={() => {
                          let data = this.state.data;
                          data.feedback.nameFocus = false;
                          this.setState({ data: data });
                        }}
                      />
                      {this.renderError('feedback', 'name')}
                    </div>
                  </div>

                  <div
                    className={
                      'form__item ' + (this.state.topicInput !== '' ? 'form-focused' : '')
                    }>
                    <div
                      className={[
                        'form__field form__select ',
                        this.state.errors.feedback.topic === true ? 'has-error' : '',
                      ].join(' ')}>
                      <div className="select__custom">
                        <div
                          className="form__label"
                          onClick={() => {
                            this.setState({
                              topicDropdown: true,
                            });
                          }}>
                          Тема
                        </div>
                        <div
                          onClick={() => this.toggleTopicDropdown()}
                          className="select__custom-input d-flex align-items-center">
                          <span>{this.state.topicInput}</span>
                          <div className="select__custom-arrow d-flex align-items-center justify-content-center">
                            <i
                              className={
                                'select__arrow ' +
                                (this.state.topicDropdown === true ? 'active' : '')
                              }></i>
                          </div>
                        </div>
                        <div
                          className={
                            'select__custom-dropdown ' +
                            (this.state.topicDropdown === true ? 'active' : '')
                          }>
                          <div className="select__custom-dropdown-list">
                            <div
                              onClick={() => this.selectTopic(1, 'Регистрация в акции')}
                              className={
                                'select__custom-dropdown-item ' +
                                (this.state.data.feedback.topic === 1 ? 'active' : '')
                              }>
                              Регистрация в акции
                            </div>
                            <div
                              onClick={() => this.selectTopic(2, 'Регистрация чека')}
                              className={
                                'select__custom-dropdown-item ' +
                                (this.state.data.feedback.topic === 2 ? 'active' : '')
                              }>
                              Регистрация чека
                            </div>
                            <div
                              onClick={() => this.selectTopic(3, 'Призовой фонд')}
                              className={
                                'select__custom-dropdown-item ' +
                                (this.state.data.feedback.topic === 3 ? 'active' : '')
                              }>
                              Призовой фонд
                            </div>
                            <div
                              onClick={() => this.selectTopic(4, 'Вопросы о продукте')}
                              className={
                                'select__custom-dropdown-item ' +
                                (this.state.data.feedback.topic === 4 ? 'active' : '')
                              }>
                              Вопросы о продукте
                            </div>
                            <div
                              onClick={() => this.selectTopic(5, 'Технические вопросы')}
                              className={
                                'select__custom-dropdown-item ' +
                                (this.state.data.feedback.topic === 5 ? 'active' : '')
                              }>
                              Технические вопросы
                            </div>
                            <div
                              onClick={() => this.selectTopic(6, 'Другое')}
                              className={
                                'select__custom-dropdown-item ' +
                                (this.state.data.feedback.topic === 6 ? 'active' : '')
                              }>
                              Другое
                            </div>
                          </div>
                        </div>
                      </div>

                      {this.renderError('feedback', 'topic')}
                    </div>
                  </div>
                  <div
                    className={
                      'form__item ' +
                      (this.state.data.feedback.messageFocus === true ||
                      this.state.data.feedback.message !== ''
                        ? 'form-focused'
                        : '')
                    }>
                    <div
                      className={[
                        'form__field',
                        this.state.errors.feedback.message === true ? 'has-error' : '',
                      ].join(' ')}>
                      <div
                        className="form__label"
                        onClick={() => {
                          this.messInput.focus();
                        }}>
                        Сообщение
                      </div>
                      <textarea
                        placeholder="Текст обращения"
                        ref={(mess) => {
                          this.messInput = mess;
                        }}
                        name="message"
                        autoComplete="off"
                        onFocus={() => {
                          let errors = this.state.errors;
                          let data = this.state.data;
                          data.feedback.messageFocus = true;
                          errors.feedback.message = false;
                          this.setState({ errors: errors, data: data });
                        }}
                        value={this.state.data.feedback.message}
                        onChange={(message) => {
                          let data = this.state.data;
                          data.feedback.message = message.target.value;
                          this.setState({ data: data });
                        }}
                        onBlur={() => {
                          let data = this.state.data;
                          data.feedback.messageFocus = false;
                          this.setState({ data: data });
                        }}
                      />
                      {this.renderError('feedback', 'message')}
                    </div>
                  </div>
                  <div className="form__item form__item_for-processing">
                    <p>
                      Нажимая на&nbsp;кнопку &laquo;Отправить&raquo;, вы&nbsp;даете согласие
                      на&nbsp;обработку{' '}
                      <a href={this.state.agreement} target="_blank">
                        персональных данных
                      </a>
                    </p>
                  </div>
                  <div className="form__item form__item-actions d-flex justify-content-center">
                    <div
                      className={
                        'form__action d-flex justify-content-center ' +
                        (this.state.loading === true ? 'disabled' : '')
                      }>
                      <div
                        onClick={() => {
                          this.submitFeedback();
                        }}
                        className="btn__custom d-flex align-items-center justify-content-center">
                        <span>Отправить</span>
                        <div className="loader">
                          <img src={loader}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-show={this.state.modal__del}
          className="modal__custom modal__custom-del modal__custom-small">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={this.modalClose}></div>

            <div className="modal__box-content">
              <div className="modal__box-title">Вы уверены, что хотите удалить аккаунт?</div>
              <div className="actions__wrap btn__list d-flex align-items-center justify-content-center">
                <div
                  onClick={() => {
                    this.modalClose();
                    this.props.runDel();
                  }}
                  className="btn__custom btn__custom-dark d-flex align-items-center justify-content-center">
                  <span>Да</span>
                </div>
                <div
                  onClick={() => {
                    this.modalClose();
                  }}
                  className="btn__custom btn__custom-border d-flex align-items-center justify-content-center">
                  <span>Нет</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-show={this.state.modal__info_1}
          className="modal__custom modal__custom-info modal__custom-small">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={this.modalClose}></div>

            <div className="modal__box-content">
              <div className="modal__box-title">Спасибо</div>
              <div className="modal__header-text">
                Мы&nbsp;отправили Ваш пароль
                <br />
                на&nbsp;указанный&nbsp;E-mail
              </div>
            </div>
          </div>
        </div>
        <div
          data-show={this.state.modal__info_2}
          className="modal__custom modal__custom-info modal__custom-small">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={this.modalClose}></div>

            <div className="modal__box-content">
              <div className="modal__box-title">Спасибо!</div>
              <div className="modal__header-text">
                Данные в&nbsp;вашем профиле успешно обновлены
              </div>
            </div>
          </div>
        </div>
        <div
          data-show={this.state.modal__info_3}
          className="modal__custom modal__custom-info modal__custom-small">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={this.modalClose}></div>

            <div className="modal__box-content">
              <div className="modal__box-title">
                Завершите
                <br />
                регистрацию
              </div>
              <div className="modal__header-text">
                Чтобы завершить регистрацию, перейдите, по&nbsp;ссылке, отправленной на&nbsp;Вашу
                электронную почту.
              </div>
              <div className="modal__btn d-flex justify-content-center">
                <div
                  onClick={() => {
                    this.modalClose();
                  }}
                  className="btn__custom btn__custom-dark d-flex align-items-center justify-content-center">
                  <span>Продолжить</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-show={this.state.modal__info_4}
          className="modal__custom modal__custom-info modal__custom-small modal__custom-thank">
          <div className="modal__box">
            <div
              className="modal__close d-flex align-items-center justify-content-center"
              onClick={this.modalClose}></div>

            <div className="modal__box-content">
              <div className="modal__box-title">Спасибо за&nbsp;обращение</div>
              <div className="modal__header-text">
                <p>
                  ВАШ ВОПРОС БУДЕТ ОБРАБОТАН В ТЕЧЕНИЕ 72 ЧАСОВ. Мы&nbsp;пришлем ответ&nbsp;на{' '}
                  {localStorage.getItem('feedbackEmail') &&
                    localStorage.getItem('feedbackEmail') != '' &&
                    localStorage.getItem('feedbackEmail')}{' '}
                  ожидайте.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          data-show={this.state.modal__info_5}
          className="modal__custom modal__custom5 modal__custom-info modal__custom-small">
          <div className="modal__box">
            <div className="modal__logo"></div>

            <div className="modal__box-content">
              <div className="modal__box-title">
                новая акция
                <br />
                &laquo;накупи на&nbsp;ремонт <br />
                с&nbsp;чаем &laquo;принцесса нури&raquo;
              </div>
              <div className="modal__header-text">
                <p>
                  начнётся <br />
                  15.03.22
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {this.render5Show()}
        {this.render6Show()}
        {this.render7Show()}
        {this.render8Show()}
        {this.renderModalPrize()}
        {this.render9Show()}
        {this.render10Show()}
        {this.renderNewAction()}
      </div>
    );
  }
}
